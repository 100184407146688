// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  Hidden,
  Slide,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CourseManagementDetailsController, {
  Props,
  courseDetailsTabList,
} from "./CourseManagementDetailsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import StudentProgressJourneyDesktop from "./StudentProgressJourneyDesktop.web";
import StudentProgressJourneyMobileView from "./StudentProgressJourneyMobileView.web";
import TeacherCourseMngResourseTab from "./TeacherCourseMngResourseTab.web";
import TeacherParticipantsTab from "./TeacherParticipantsTab.web";
import CustomButton from "../../../components/src/CustomButton.web";
import AddNewResourceModal from "./AddNewResourceModal.web";
import TenantCourseLearnMore from "./TenantCourseLearnMore.web";
import TenantAssignCatalogToTeacherModal from "./TenantAssignCatalogToTeacherModal.web";
import TenantAssignCatalogToTeacherConfirmModalWeb from "./TenantAssignCatalogToTeacherConfirmModal.web";
import TenantAssignCatalogToTeacherSuccessModalWeb from "./TenantAssignCatalogToTeacherSuccessModal.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import DeleteConfirmDialog from "./DeleteConfirmDialog.web";
import CustomImageComponentWeb from "../../../components/src/CustomImageComponent.web";
import CourseManagementDetailsTopHeaderWeb from "./CourseManagementDetailsTopHeader.web";
import PublishCourseConfirmDialog from "./PublishCourseConfirmDialog.web";
import { USER_ROLES_CAMEL } from "../../../components/src/CommonType.web";
import TenantCourseManagementRejectAcceptRequestModalWeb from "./TenantCourseManagementRejectAcceptRequestModal.web";
import TenantCourseManagementRejectReasonModalWeb from "./TenantCourseManagementRejectReasonModal.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    studentNameGrid: {
      marginTop: "0px",
      marginBottom: "16px",
    },
    coursesDetailsTabMainContainer: {
      height: "100%",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "33.33%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    coursesDetailsTabPanel: {
      padding: "0px 0px 15px 0px",
    },
    marginRightBox: {
      marginRight: "8px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    learnMoreBox: {
      zIndex: 2,
      boxShadow: "-2px 1px 5px 2px #8888885e",
      width: "570px",
      height: "140vh",
      position: "fixed",
      right: "0px",
      top: "0px",
      bottom: "0px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "143vh",
      },
    },
    creatorDetailsBox: {
      borderRadius: "10px",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        overflow: "auto",
      },
      "& >div": {
        padding: "17px",
      },
    },
    titleCase: {
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingRight: "20px",
    },
  });
// Customizable Area End

// Customizable Area Start
export class CourseManagementDetails extends CourseManagementDetailsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      tabValue,
      selectedCourseSubject,
      openAddNewResourceModal,
      isTeacherCourse,
      courseName,
      selectedLessonName,
      selectedTopicName,
      openLearnMore,
      openAssignCatalogToTeacherPopup,
      openAssignCatalogConfirmModal,
      openAssignCatalogSuccessModal,
      isEditResource,
      editResourceId,
      openDeleteConfirmDialog,
      deleteResourceTitle,
      searching,
      resourcePaginationData,
      courseId,
      isFromStudentTeacher,
      studentName,
      studentProfileUrl,
      requestType,
      openPublishCourseConfirmPopup,
      selectedCourseDetail,
      role,
      isRejectConfirmModalOpen,
      isRejectReasonModalOpen,
      rejectReason,
      resourceTableData,
      StudentId
    } = this.state;
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor} ${classes.mainPagePadding}`}
        height={"100%"}
      >
        <Grid
          id={`tenant-course-detail-main-container`}
          container
          direction="row"
        >
          <Grid item xs={12}>
            <CourseManagementDetailsTopHeaderWeb
              courseName={courseName}
              isFromStudentTeacher={isFromStudentTeacher}
              isTeacherCourse={isTeacherCourse}
              selectedLessonName={selectedLessonName}
              selectedTopicName={selectedTopicName}
              studentName={studentName}
              tabValue={tabValue}
            />
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
              id={`tenant-course-detail-main-header-box`}
            >
              <Box
                className={`${classes.cursorPointer}`}
                alignItems={"center"}
                display={"flex"}
                overflow={"hidden"}
              >
                <Box className={classes.marginRightBox}>
                  <KeyboardBackspaceIcon
                    onClick={() => this.handleGoBack()}
                    data-test-id={`tenant-course-detail-back-arrow`}
                    className={`${classes.textPrimaryColor}`}
                    fontSize="large"
                  />
                </Box>
                <Typography
                  className={`${classes.titleCase} ${classes.fontText34} ${classes.textPrimaryColor} ${classes.fontBold600}`}
                  component={"span"}
                >
                  {courseName.trim().replace(/%2C/g, ",")}
                </Typography>
              </Box>
              <Box>
                {requestType && requestType === "pending" ?
                  <>
                    {
                      this.state.role === USER_ROLES_CAMEL.TENANT_ADMIN && (
                        <>
                          <CustomButton
                            btnText="Reject"
                            btnStyle={rejectBtnStyle}
                            buttonId={`add-new-resource`}
                            buttonVariant={'contained'}
                            type={'button'}
                            handleButtonClick={this.openRejectConfirmModal}
                            isDisabled={false}
                          />
                          <CustomButton
                            btnText="Accept"
                            btnStyle={acceptBtnStyle}
                            buttonId={`add-new-resource`}
                            buttonVariant={'contained'}
                            type={'button'}
                            handleButtonClick={this.handlePendingRequest(true)}
                            isDisabled={false}
                          />
                        </>
                      )
                    }
                  </>
                  :
                  <>
                    {!isTeacherCourse ? (
                      <>
                        {tabValue === 0 && (
                          <CustomButton
                            btnText="Add New Resource"
                            btnStyle={addNewResourceBtnStyle}
                            buttonId={`add-new-resource`}
                            buttonVariant={"contained"}
                            type={"button"}
                            handleButtonClick={this.handleOpenAddNewResourceModal}
                            isDisabled={false}
                          />
                        )}
                        {tabValue === 1 && !isFromStudentTeacher && (
                          <Typography
                            className={`${classes.textButton} ${classes.cursorPointer} ${classes.fontText18} ${classes.fontBold600}`}
                            component={"span"}
                            onClick={() => this.handleOpenLearnMore()}
                            data-test-id="tenant-course-learn-more"
                          >
                            Learn More
                          </Typography>
                        )}
                      </>
                    ) : (
                      <>
                        {tabValue === 0 && (
                          <Box>
                            <CustomButton
                              btnText="Add New Resource"
                              btnStyle={addNewResourceBtnStyle}
                              buttonId={`add-new-resource`}
                              buttonVariant={"outlined"}
                              type={"button"}
                              handleButtonClick={this.handleOpenAddNewResourceModal}
                              isDisabled={false}
                            />
                            {selectedCourseDetail?.attributes?.status === "Draft" &&
                              <CustomButton
                                btnText="Send Approval Request"
                                btnStyle={addNewResourceBtnStyle}
                                buttonId={`add-new-resource`}
                                buttonVariant={"contained"}
                                type={"button"}
                                handleButtonClick={this.handleOpenPublishCourseConfirmPopup}
                                isDisabled={false}
                              />}
                          </Box>
                        )}
                        {(tabValue === 1 || tabValue === 2) && !isFromStudentTeacher && (
                          <Typography
                            className={`${classes.textButton} ${classes.cursorPointer} ${classes.fontText18} ${classes.fontBold600}`}
                            component={"span"}
                            onClick={() => this.handleOpenLearnMore()}
                            data-test-id="tenant-course-learn-more"
                          >
                            Learn More
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                }
              </Box>
            </Box>
          </Grid>
          {isFromStudentTeacher && (
            <Grid className={classes.studentNameGrid} item xs={12}>
              <Box width={"98%"} display={"flex"} alignItems={"center"}>
                <CustomImageComponentWeb
                  altText="student-profile"
                  src={studentProfileUrl}
                  imgStyles={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                  isUserProfileImg
                />
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText22}`}
                  component={"span"}
                >
                  {`${studentName}`}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              className={`${classes.bgColor} ${classes.coursesDetailsTabMainContainer}`}
            >
              <Box>
                <Tabs
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                  scrollButtons="auto"
                  data-testid="tenant-course-management-details-tabs"
                  value={tabValue}
                  className={`${classes.tabs}`}
                  onChange={this.handleChangeTab}
                >
                  {courseDetailsTabList.map((tabItem: any, index: number) => (
                    <Tab
                      key={tabItem.id}
                      data-testid={`tenant-course-management-details-${tabItem.label}-tab`}
                      label={tabItem.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box className={`${classes.coursesDetailsTabPanel}`}>
                <TabPanel value={tabValue} index={0}>
                  {this.state.resourceTableData.length === 0 && !searching ? (
                    <CustomEmptyResultComponent
                      message="No resource added"
                      showActionButton={true}
                      buttonText="Add a Resource"
                      handleButtonClick={this.handleOpenAddNewResourceModal}
                    />
                  ) : (
                    <TeacherCourseMngResourseTab
                      resourceData={this.state.resourceTableData}
                      getEditResourceId={(data: any) =>
                        this.handleGetEditResourceId(data)
                      }
                      getSearchQueryData={(data: any) =>
                        this.handleResourceSearchQuery(data)
                      }
                      getDeleteResourceData={(data: any) =>
                        this.handleResourceDeleteOperation(data)
                      }
                      pagination={resourcePaginationData}
                      getPaginationNumber={(page: number) =>
                        this.handleResourcePaginationChange(page)
                      }
                    />
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Grid container direction="row">
                    {/* Topic Lessons Activity Box */}
                    <Grid item xs={12}>
                      <StudentProgressJourneyDesktop
                        navigation={navigation}
                        isTeacherCourse={isTeacherCourse}
                        isFromTenant={this.handleIsFromTenant()}
                        handleSetLessonName={this.handleSetSubjectLessonName}
                        selectedSubjectName={selectedCourseSubject}
                        handleSetTopicName={this.handleSetLessonTopicName}
                        courseData={selectedCourseDetail}
                        isCourseMangement={role==="Parent Teacher"}
                        isFromStudentTeacher={isFromStudentTeacher}
                        StudentId={StudentId}
                        role={role}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <TeacherParticipantsTab />
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {openAddNewResourceModal &&
          selectedCourseDetail?.attributes && (
            <AddNewResourceModal
              onCancel={this.handleCloseAddNewResourceModal}
              open={openAddNewResourceModal}
              onConfirm={this.handleCloseAddNewResourceModal}
              courseData={selectedCourseDetail}
              isEdit={isEditResource}
              resourceId={editResourceId}
              handleAddNewResource={(data: any) =>
                this.handleAddNewResourceOperation(data)
              }
              handleUpdateNewResource={(data: any) =>
                this.handleUpdateNewResourceOperation(data)
              }
            />
          )}

        {openDeleteConfirmDialog && (
          <DeleteConfirmDialog
            open={openDeleteConfirmDialog}
            onCancel={this.handleCloseDeleteConfirmDialog}
            onConfirm={this.handleDeleteConfirmAction}
            itemTitle={deleteResourceTitle}
            headingTitle="Resource"
          />
        )}

        {openPublishCourseConfirmPopup &&
          <PublishCourseConfirmDialog
            handleCancel={this.handleClosePublishCourseConfirmPopup}
            open={openPublishCourseConfirmPopup}
            handleYes={this.handleSubmitCourseForApproval}
            handleClose={this.handleClosePublishCourseConfirmPopup}
            detailsForConfirmation={{
              subject: selectedCourseDetail?.attributes?.subject?.name,
              grade: selectedCourseDetail?.attributes?.grade?.name,
              topic: selectedCourseDetail?.attributes?.course_title,
            }}
          />
        }

        <TenantAssignCatalogToTeacherModal
          open={openAssignCatalogToTeacherPopup}
          onClose={this.handleCloseAssignCatalogModal}
          onConfirm={this.handleOpenConfirmAssignCatalogModal}
          getTeacherIds={() => { }}
        />

        <TenantAssignCatalogToTeacherConfirmModalWeb
          open={openAssignCatalogConfirmModal}
          onCancel={this.handleCloseConfirmAssignCatalogModal}
          onConfirm={this.handleOpenAssignCatalogSuccessModal}
        />

        <TenantAssignCatalogToTeacherSuccessModalWeb
          open={openAssignCatalogSuccessModal}
          onOkay={this.handleCloseAssignCatalogSuccessModal}
          onCancel={this.handleCloseAssignCatalogSuccessModal}
        />

        <TenantCourseManagementRejectAcceptRequestModalWeb
          open={isRejectConfirmModalOpen}
          handleClose={this.closeRejectConfirmModal}
          detailsForApproval={{
            grade: resourceTableData[0]?.Grade_Resourse || "",
            subject: resourceTableData[0]?.Subject_Resourse || "",
            topic: courseName,
          }}
          rejectRequest={true}
          handleCancel={this.closeRejectConfirmModal}
          handleYes={() => { 
            this.closeRejectConfirmModal(); 
            this.openRejectReasonModal();
          }}
          isFromAssignCourseToCatalog={false}
        />
        
        <TenantCourseManagementRejectReasonModalWeb
          open={isRejectReasonModalOpen}
          handleText={this.editRejectReason}
          handleAdd={this.handlePendingRequest(false)}
          handleCancel={this.closeRejectReasonModal}
          handleClose={this.closeRejectReasonModal}
          rejectionReason={rejectReason}
        />

        <Slide direction="left" in={openLearnMore} mountOnEnter unmountOnExit>
          <Box className={`${classes.learnMoreBox} ${classes.bgColor}`}>
            <TenantCourseLearnMore
              courseId={courseId}
              isFromTenant={this.handleIsFromTenant()}
              courseName={courseName.trim().replace(/%2C/g, ",")}
              handleAssign={() => this.handleOpenAssignCatalogModal()}
              handleClose={this.handleCloseLearnMore}
              handleEdit={this.handleCloseLearnMore}
            />
          </Box>
        </Slide>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addNewResourceBtnStyle = {
  width: "max-content",
  height: "50px",
  padding: "15px 20px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
  marginLeft: "15px",
};

const acceptBtnStyle = {
  width: "150px",
  padding: "15px 20px",
  marginLeft: "15px",
  height: "50px",
  fontSize: "18px",
  textAlign: "center",
  borderRadius: "12px",
  fontWeight: 500,
};

const rejectBtnStyle = {
  background: "red",
  width: "150px",
  height: "50px",
  padding: "15px 20px",
  borderRadius: "12px",
  fontSize: "18px",
  fontWeight: 500,
  textAlign: "center",
};
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(CourseManagementDetails);
// Customizable Area End
