// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  FIELD_TYPES,
  ICustomTableProps,
  IDropdownMenuItemProps,
  IInputConfigProps,
  ITableActionBtnConfigProps,
  TOASTER_NOTIFICATION_MSG,
  USER_ROLES_CAMEL,
} from "../../../components/src/CommonType.web";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import {
  catalogTableBodyDataMock,
  courseUtilizationListMock,
  coursesTableHeaderColumnsMock,
  curriculumListMock,
  customField1ListMock,
  gradeLevelListMock,
  schoolYearListMock,
  subjectsListMock,
  teacherNameListMock,
} from "../assets/tenantCourseManagementMockdata";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import _ from "lodash";
import CustomButton from "../../../components/src/CustomButton.web";
import React, { ChangeEvent } from "react";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
interface Subject {
  icon?: string;
  color?: string;
  name: string;
}

interface Grade {
  name: string;
}

interface ModifiedBy {
  first_name: string;
  last_name: string;
}

interface CreatedBy {
  first_name: string;
  last_name: string;
}

interface CourseAttributes {
  course_title: string;
  subject?: Subject;
  grade?: Grade;
  no_of_lession_a_day: number;
  total_no_of_lessons: number;
  modified_by?: ModifiedBy;
  user?: CreatedBy;
  school_year?: { title?: string };
  updated_course?: { status?: string };
}

interface SubjectItem {
  color: string; 
  content_type: string; 
  filename: string;
  icon: string; 
  id: number; 
  image: string | null; 
  name: string; 
}
interface CourseDataItem {
  id: string;
  title: {
    name: string;
    icon?: string;
    bgColor: string;
    boxWidth: string;
    boxHeight: string;
  };
  subject: string;
  grade: string;
  noOfLessonsPerDay: number;
  totalNumberOfLessons: number;
  modifiedBy: string;
  createdBy: string;
  schoolYear: string;
  isByTenant: boolean;
  status: string;
  disableKey?: boolean; 
  subjects:SubjectItem
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedCourseManagementTab: number;
  searchTitleText: string;
  openCreateNewCatalogModal: boolean;
  teacherNamesList: Array<any>;
  teacherNameDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedTeacherNameDDValue: any;
  gradeLevelList: Array<any>;
  selectedGradeLevelDDValue: any;
  gradeLevelDDMenuItems: Array<IDropdownMenuItemProps>;
  curriculumList: Array<any>;
  curriculumDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedCurriculumDDValue: any;
  schoolYearList: Array<any>;
  schoolYearDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedSchoolYearDDValue: any;
  subjectHandledList: Array<any>;
  subjectHandledDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedSubjectHandledDDValue: string;
  courseUtilizationList: Array<any>;
  courseUtilizationDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedCourseUtilizationDDValue: any;
  customFieldList: Array<any>;
  customFieldDDMenuItems: Array<IDropdownMenuItemProps>;
  selectedCustomFieldDDValue: any;
  catalogList: Array<any>;
  catalogTableHeaderColumns: Array<ICustomTableProps>;
  tableHeaderColumns: Array<ICustomTableProps>;
  tableBodyData: Array<any>;
  ddConfig: Array<IInputConfigProps>;
  tableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  openCreateNewCoursePopup: boolean;
  openCreateCourseConfirmPopup: boolean;
  isEditCourse: boolean;
  coursesTableData: Array<any>;
  courseRequestBody: any;
  editCourseId: number;
  subjectsDropdownList: Array<any>;
  schoolYearDropdownList: Array<any>;
  gradesDropdownList: Array<any>;
  createUpdateSubject: string;
  createUpdateGrade: string;
  createUpdateTitle: string;
  paginationData: any;
  paginationLimit: number;
  catalogTableData: Array<any>;
  catalogPaginationData: any;
  courseMngTabsList: Array<any>;
  role: string;
  openPublishCourseConfirmPopup: boolean;
  openConfirmSendApprovalDialog: boolean;
  teacherPublishCourseId: number;
  teacherCopyCourseId: number;
  openCopyCourseSuccessDialog: boolean;
  viewCatalogCourses: boolean;
  viewCatalogCoursesId: string;
  viewCatalogCoursesName: string;
  viewCatalogCoursesData: Array<any>;
  isEditCatalog: boolean;
  editCatalogId: number;
  openAssignCatalogToTeacherPopup: boolean;
  openAssignCourseModal: boolean;
  assignCourseDetail: any;
  openAssignCourseSuccessDialog: boolean;
  assignCatalogDetail: any;
  clickedRowId: string;
  rowWiseTableActionConfig: Array<any>;
  timerId: NodeJS.Timeout | null; 
  shouldCourseAddInCatalog: boolean;
  isAssignedDetailsModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class CourseManagementMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostAddNewCourse: string = "";
  apiGetCourseList: string = "";
  apiPutUpdateCourse: string = "";
  apiGetTeachersDropdownData: string = "";
  apiGetSubjectsDropdownData: string = "";
  apiGetSchoolYearDropdownData: string = "";
  apiGetGradesDropdownData: string = "";
  apiPostAddNewCatalog: string = "";
  apiGetCatalogList: string = "";
  apiPostTeacherPublishCourse: string = "";
  apiPostTeacherCopyCourse: string = "";
  apiPutUpdateCatalog: string = "";
  apiGetCurriculumDropdownData: string = "";
  apiGetTeachersList: string = "";
  apiPostAssignCatelogToTeacher: string = "";
  apiPostAssignCourseToStudent: string = "";
  apiParentPostAssignCourseToStudent:string="";
  apiGetSearchCatalogCourse: string = "";
  apiGetSearchCatalog: string = "";
  removeCourseFromCatalogRequest: string = "";
  apiSearchCourse: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      selectedCourseManagementTab: 0,
      searchTitleText: "",
      openCreateNewCatalogModal: false,
      teacherNamesList: [],
      teacherNameDDMenuItems: teacherNameListMock,
      selectedTeacherNameDDValue: "",
      gradeLevelDDMenuItems: gradeLevelListMock,
      gradeLevelList: [],
      selectedGradeLevelDDValue: "",
      curriculumList: [],
      curriculumDDMenuItems: curriculumListMock,
      selectedCurriculumDDValue: "",
      subjectHandledList: [],
      subjectHandledDDMenuItems: subjectsListMock,
      selectedSubjectHandledDDValue: "",
      schoolYearDDMenuItems: schoolYearListMock,
      schoolYearList: [],
      selectedSchoolYearDDValue: "",
      customFieldList: [],
      customFieldDDMenuItems: customField1ListMock,
      selectedCustomFieldDDValue: "",
      courseUtilizationList: [],
      courseUtilizationDDMenuItems: courseUtilizationListMock,
      selectedCourseUtilizationDDValue: "",
      catalogList: catalogTableBodyDataMock,
      tableHeaderColumns: [],
      tableBodyData: [],
      catalogTableHeaderColumns: coursesTableHeaderColumnsMock,
      ddConfig: [],
      tableActionButtonConfig: [],
      openCreateNewCoursePopup: false,
      openCreateCourseConfirmPopup: false,
      isEditCourse: false,
      coursesTableData: [],
      courseRequestBody: {},
      editCourseId: 0,
      subjectsDropdownList: [],
      schoolYearDropdownList: [],
      gradesDropdownList: [],
      createUpdateTitle: "",
      createUpdateSubject: "",
      createUpdateGrade: "",
      paginationData: {},
      paginationLimit: 10,
      catalogTableData: [],
      catalogPaginationData: {},
      courseMngTabsList: [
        {
          id: 1,
          label: "Courses",
          value: "teacher_courses",
        },
        {
          id: 2,
          label: "Catalog",
          value: "catalog",
        },
      ],
      role: "",
      openPublishCourseConfirmPopup: false,
      openConfirmSendApprovalDialog: false,
      teacherPublishCourseId: 0,
      teacherCopyCourseId: 0,
      openCopyCourseSuccessDialog: false,
      viewCatalogCourses: false,
      viewCatalogCoursesId: '',
      viewCatalogCoursesName: '',
      viewCatalogCoursesData: [],
      isEditCatalog: false,
      editCatalogId: 0,
      openAssignCatalogToTeacherPopup: false,
      openAssignCourseModal: false,
      assignCourseDetail: {},
      openAssignCourseSuccessDialog: false,
      assignCatalogDetail: {},
      clickedRowId: '',
      rowWiseTableActionConfig: [],
      timerId: null,
      shouldCourseAddInCatalog: false,
      isAssignedDetailsModalOpen: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  handleToggle = () => {
    this.setState({role: ""})
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiPostAddNewCourse:
            {
              this.handlePostNewCourseDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiPutUpdateCourse:
            {
              this.handleUpdateCourseDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetCourseList:
            {
              this.handleCourseListResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetSubjectsDropdownData:
            {
              this.handleSubjectsDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiSearchCourse:
            {
              this.handleSearchCourseResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetSchoolYearDropdownData:
            {
              this.handleSchoolYearDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetTeachersDropdownData:
            {
              this.handleTeachersDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetGradesDropdownData:
            {
              this.handleGradesDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiPostAddNewCatalog:
            {
              this.handlePostNewCatalogDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetCatalogList:
            {
              this.handleCatalogListResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiPostTeacherPublishCourse:
            {
              this.handlePostTeacherPublishCourseResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiPostTeacherCopyCourse:
            {
              this.handlePostTeacherCopyCourseResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiPutUpdateCatalog:
            {
              this.handleUpdateCatalogDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetCurriculumDropdownData:
            {
              this.handleCurriculumDropdownResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetTeachersList:
            {
              this.handleGetTeachersListResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiPostAssignCatelogToTeacher:
            {
              this.handleAssignCatalogDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;

          case this.apiGetSearchCatalogCourse:
            {
              this.handleSearchCatalogCourseResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiPostAssignCourseToStudent:
            {
              this.handlePostAssignCourseToStudentResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetSearchCatalog:
            {
              this.handleSearchCatalogueResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.removeCourseFromCatalogRequest:
            {
              this.handleRemoveCourseFromCatalogResponse(
                responseJson,
                errorResponse
              );
              this.handleLoadCourseTab();
            }
            break;
          case this.apiParentPostAssignCourseToStudent:{
            this.handleParentPostAssignCourseToStudentResponse(responseJson,errorResponse)
          }
        }
      }
    }
    
    const isNavigationPayload =
      message.id === getName(MessageEnum.NavigationPayLoadMessage);
   
    if (isNavigationPayload) {
      const data = message.getData(MessageEnum.NavigationPayLoadMessage);

      this.handleChangeMyPathCategoryTab({} as ChangeEvent, data?.tabIndex || 1);
    }
  }
  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.setState({ role: await getStorageData("role") });
    this.setCoursesTabTitle();
    this.handleLoadCourseTab();
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangeTextFieldValue = (event: any) => {
    const { name, value } = event.target;

    const isCatalogTabSelected = this.state.selectedCourseManagementTab === 1;
    const isAnyCourseSelected = !!this.state.viewCatalogCoursesId;
    
    const previousTimerId = this.state.timerId

    if (previousTimerId) clearTimeout(previousTimerId);

    const latestTimerId = setTimeout(() => {
      const catalogId = Number(this.state.viewCatalogCoursesId);

      if (isCatalogTabSelected && isAnyCourseSelected) this.searchCatalogCourse(value, catalogId);
      else if (isCatalogTabSelected) this.searchCatalog(value);
      else this.searchCourse(value);
    }, configJSON.searchCatalogCourseDebounce as number)

    
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
        timerId: latestTimerId
      };
    });
  };

  /**
   * Filters catalogs based on searchTitleText
   */
  searchCourse = async (query: string, page?: number) => {
    showCustomLoader();
    
    const convertedQuery = this.convertSpaces(query);
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSearchCourse = request.messageId;

    const endpoint = configJSON.getCourseListData;
    const limit = this.state.paginationLimit;
    let path =  `${endpoint}?per_page=${limit}&title_search=${convertedQuery}`;

    const {
      selectedSchoolYearDDValue,
      selectedSubjectHandledDDValue,
      selectedGradeLevelDDValue,
      selectedTeacherNameDDValue,
    } = this.state;

    const filters = {
      subject_id: selectedSubjectHandledDDValue,
      grade_id: selectedGradeLevelDDValue,
      school_year_id: selectedSchoolYearDDValue,
      user_id: selectedTeacherNameDDValue,
      page: page
    }
    
    if (filters.subject_id) {
      path = path + `&subject_id=${filters.subject_id}`;
    }
    if (filters.grade_id) {
      path = path + `&grade_id=${filters.grade_id}`;
    }
    if (filters.user_id) {
      path = path + `&user_id=${filters.user_id}`;
    }
    if (filters.school_year_id) {
      path = path + `&school_year_id=${filters.school_year_id}`;
    }
    if (filters.page) {
      path = path + `&page=${filters.page}`;
    }
    
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      path
    );

    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const getMethod = configJSON.validationApiMethodType;
    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getMethod
    );


    runEngine.sendMessage(request.id, request);
  }

  /**
   * Converts spaces to its URL encoding equivalent
   * @param str - the text with spaces
   * @returns - text with spaces converted to %20
   */
  convertSpaces = (str: string): string => str.replace(/ /g, '%20')

  /**
   * Sends a GET request to getCatalogCourses endpoint with search parameter
   */
  searchCatalogCourse = async (query: string, catalogId: number) => {
    showCustomLoader();
    
    const convertedQuery = this.convertSpaces(query);
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSearchCatalogCourse = request.messageId;

    const path = configJSON.getCatalogCourses;
    const limit = this.state.paginationLimit;
    const endpoint =  `${path}?per_page=${limit}&catalogue_id=${catalogId}&title_search=${convertedQuery}`;
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const getMethod = configJSON.validationApiMethodType;
    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getMethod
    );


    runEngine.sendMessage(request.id, request);
  }
  /**
   * Sends a GET request to catalog endpoint with search parameter
   */
  searchCatalog = async (query: string) => {
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSearchCatalog = request.messageId;
    showCustomLoader();

    const { paginationLimit } = this.state
    const getCatalogue = configJSON.postAddNewCatalog;
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${getCatalogue}?per_page=${paginationLimit}&title_search=${query}`
    );

    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(request.id, request);
  }

  handleChangeMyPathCategoryTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    this.setState({
      tableBodyData: [],
      coursesTableData: [],
      catalogTableData: [],
      tableHeaderColumns: [],
      ddConfig: [],
      tableActionButtonConfig: [],
      loading: false,
      selectedCourseManagementTab: newValue,
      paginationData: {},
      catalogPaginationData: {},
      viewCatalogCoursesData: [],
      viewCatalogCourses: false,
      viewCatalogCoursesName: '',
      viewCatalogCoursesId: '',
      selectedSchoolYearDDValue: '',
      selectedGradeLevelDDValue: '',
      selectedSubjectHandledDDValue: '',
      selectedTeacherNameDDValue: '',
      selectedCurriculumDDValue: '',
    }, () => {
      if (newValue === 0) {
        this.handleLoadCourseTab();
      } else if (newValue === 1) {
        this.handleLoadCatalogTab();
      }
    });
  };

  handleManageRequest = () => {
    this.setState({ loading: false });
    this.props.navigation.navigate("CourseManagementManageRequestMain");
  };

  handleCreateNewCourse = () => {
    this.setState({ openCreateNewCoursePopup: true });
  };

  handleEditCourse = (value: any) => {
    this.setState({ isEditCourse: true, editCourseId: value }, () => {
      this.setState({ openCreateNewCoursePopup: true });
    });
  };

  handleLoadCourseTab = () => {
    this.handleGetTeachersListApi({ user_type: 'Teacher' }, {});
    this.handleGetSubjectsDropdownListApi();
    this.handleGetGradesDropdownListApi();
    this.handleGetSchoolYearDropdownListApi();
    this.handleGetCourseListData({}, {});
  };

  handleLoadCatalogTab = () => {
    this.handleGetGradesDropdownListApi();
    this.handleGetCurriculumDropdownApi();
    this.handleGetCatalogListApi({}, {});
  };

  handleCloseCreateNewCoursePopup = () => {
    hideCustomLoader();
    this.setState({ openCreateNewCoursePopup: false, isEditCourse: false });
  };

  handleCreateNewCatalog = () => {
    hideCustomLoader();
    this.setState({ openCreateNewCatalogModal: true });
  };

  handleCloseCreateNewCatalogPopup = () => {
    hideCustomLoader();
    this.setState({ openCreateNewCatalogModal: false, isEditCatalog: false });
  };

  handleSubmitAddCourse = () => {
    this.setState({ openCreateCourseConfirmPopup: true });
  };

  handleCloseCreateCourseConfirmPopup = () => {
    this.setState({ openCreateCourseConfirmPopup: false }, () => {
      this.handleCloseCreateNewCoursePopup();
    });
  };

  setDDValue = (selected: any, fieldName: string) => {
    const {
      teacherNameDDMenuItems,
      curriculumDDMenuItems,
      customFieldDDMenuItems,
      courseUtilizationDDMenuItems,
      schoolYearDropdownList,
      subjectsDropdownList,
      gradesDropdownList
    } = this.state;

    let label: any = "";

    if (fieldName === "teacherNameDDMenuItems") {
      const selectedItem = teacherNameDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "gradeLevelDDMenuItems") {
      const selectedItem = gradesDropdownList?.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "customFieldDDMenuItems") {
      const selectedItem = customFieldDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "courseUtilizationDDMenuItems") {
      const selectedItem = courseUtilizationDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "schoolYearDDMenuItems") {
      const selectedItem = schoolYearDropdownList?.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else if (fieldName === "curriculumDDMenuItems") {
      const selectedItem = curriculumDDMenuItems.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    } else {
      const selectedItem = subjectsDropdownList?.find(
        (item: IDropdownMenuItemProps) => item.value === selected
      );
      label = selectedItem?.label;
    }
    return label;
  };

  renderDDMenuItem = (selected: any, fieldName: string, emptyText: string) => {
    if (!selected || selected.length === 0) {
      return `Select ${emptyText}`;
    }
    return this.setDDValue(selected, fieldName);
  };
  capitalizeFirstLetter(input: string): string {
    if (input.length === 0) return input;
    
    return input
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  getValue =  (disableForTeacher:boolean) => {
   return disableForTeacher ? localStorage.getItem('user_id') : ""
  }
  handleSetUserListTableData = () => {
    const { selectedCourseManagementTab, courseMngTabsList } = this.state;
    const tenantCourseMngTabCategory = courseMngTabsList[selectedCourseManagementTab].value;
    const {
      catalogList,
      catalogTableHeaderColumns,
      teacherNameDDMenuItems,
      customFieldDDMenuItems,
      courseUtilizationDDMenuItems,
      curriculumDDMenuItems,
      subjectsDropdownList,
      schoolYearDropdownList,
      gradesDropdownList,
      coursesTableData,
      role,
      viewCatalogCourses,
    } = this.state;
    let ddConfigs: Array<IInputConfigProps> = [];
    

    if (tenantCourseMngTabCategory === "teacher_courses" || viewCatalogCourses) {
      let actionButtonConfigs: Array<any> = [];
      let coursesTableColumn: Array<any> = [];
      let disableForTeacher = false;
       

      if (this.capitalizeFirstLetter(role) === USER_ROLES_CAMEL.TENANT_ADMIN || viewCatalogCourses) {
        actionButtonConfigs = [
          {
            label: "Copy",
            action: (value: any) => { this.handleCopyCourse(value) },
          },
          {
            label: "View",
            action: (value: any) => this.handleViewCourse(value),
          },
          {
            label: "Edit",
            action: (value: any) => { this.handleEditCourse(value) },
          }
        ];
        coursesTableColumn = [
          {
            columnId: 11,
            id: "title",
            label: "Title",
            type: tableColumnTypes.SUBJECT_ICON_NAME,
            width: "220px",
            maxWidth: "250px",
          },
          {
            columnId: 12,
            id: "subject",
            label: "Subject",
            type: tableColumnTypes.TEXT,
            width: "100px",
            maxWidth: "150px",
          },
          {
            columnId: 13,
            id: "grade",
            label: "Grade",
            type: tableColumnTypes.TEXT,
            width: "100px",
            maxWidth: "150px",
          },
          {
            id: "noOfLessonsPerDay",
            columnId: 14,
            label: "No. of lessons / day",
            type: tableColumnTypes.TEXT,
            width: "160px",
            maxWidth: "200px",
          },
          {
            columnId: 15,
            id: "totalNumberOfLessons",
            label: "Total No. of lessons",
            type: tableColumnTypes.TEXT,
            width: "160px",
            maxWidth: "200px",
          },
          {
            id: "modifiedBy",
            columnId: 16,
            label: "Modified By",
            type: tableColumnTypes.TEXT,
            width: "180px",
            maxWidth: "200px",
          },
          {
            id: "createdBy",
            columnId: 17,
            label: "Created By",
            type: tableColumnTypes.TEXT,
            width: "160px",
            maxWidth: "200px",
          },
          {
            id: "schoolYear",
            columnId: 18,
            label: "School Year",
            type: tableColumnTypes.TEXT,
            width: "100px",
            maxWidth: "140px",
          },
        ];
      }

      if ((this.capitalizeFirstLetter(role) === USER_ROLES_CAMEL.TEACHER || role === USER_ROLES_CAMEL.PARENT_TEACHER) && !viewCatalogCourses) {
        disableForTeacher = true;
        actionButtonConfigs = [
          {
            label: "Send For Approval",
            action: (value: any) => this.handlePublishCourse(value),
          },
          {
            label: "Copy",
            action: (value: any) => this.handleCopyCourse(value),
          },
          {
            label: "View",
            action: (value: any) => this.handleViewCourse(value),
          },
          {
            label: "Edit",
            action: (value: any) => this.handleEditCourse(value),
          },
        ];
        coursesTableColumn = [
          {
            columnId: 11,
            id: "title",
            label: "Title",
            type: tableColumnTypes.SUBJECT_ICON_NAME,
            width: "220px",
            maxWidth: "250px",
          },
          {
            columnId: 12,
            id: "subject",
            label: "Subject",
            type: tableColumnTypes.TEXT,
            width: "100px",
            maxWidth: "150px",
          },
          {
            columnId: 13,
            id: "grade",
            label: "Grade",
            type: tableColumnTypes.TEXT,
            width: "100px",
            maxWidth: "150px",
          },
          {
            columnId: 14,
            id: "totalNumberOfLessons",
            label: "Total No. of lessons",
            type: tableColumnTypes.TEXT,
            width: "160px",
            maxWidth: "200px",
          },
          {
            id: "status",
            columnId: 15,
            label: "Status",
            type: tableColumnTypes.COURSE_STATUS,
            width: "100px",
            maxWidth: "200px",
          },
        ];
      }

      if ((this.capitalizeFirstLetter(role) === USER_ROLES_CAMEL.TEACHER || role === USER_ROLES_CAMEL.PARENT_TEACHER) && viewCatalogCourses) {
        disableForTeacher = true;
        coursesTableColumn = [
          {
            columnId: 91,
            id: "title",
            type: tableColumnTypes.SUBJECT_ICON_NAME,
            width: "220px",
            label: "Title",
            maxWidth: "250px",
          },
          {
            columnId: 92,
            id: "subject",
            type: tableColumnTypes.TEXT,
            label: "Subject",
            maxWidth: "150px",
            width: "100px",
          },
          {
            columnId: 93,
            id: "grade",
            type: tableColumnTypes.TEXT,
            maxWidth: "150px",
            label: "Grade",
            width: "100px",
          },
          {
            columnId: 94,
            id: "noOfLessonsPerDay",
            type: tableColumnTypes.TEXT,
            maxWidth: "200px",
            label: "No. of lessons / day",
            width: "160px",
          },
          {
            columnId: 95,
            id: "totalNumberOfLessons",
            label: "Total No. of lessons",
            type: tableColumnTypes.TEXT,
            width: "160px",
            maxWidth: "200px",
          },
          {
            columnId: 96,
            label: "Modified By",
            id: "modifiedBy",
            type: tableColumnTypes.TEXT,
            maxWidth: "200px",
            width: "180px",
          },
          {
            columnId: 97,
            id: "createdBy",
            width: "160px",
            label: "Created By",
            type: tableColumnTypes.TEXT,
            maxWidth: "200px",
          },
          {
            columnId: 98,
            id: "schoolYear",
            type: tableColumnTypes.TEXT,
            label: "School Year",
            maxWidth: "140px",
            width: "100px",
          },
        ];
        actionButtonConfigs = [
          {
            label: "Assign to Students",
            action: (value: any) => this.handleAssignCourseToStudents(value),
          },
          {
            label: "Copy to My Courses",
            action: (value: any) => this.handleCopyCourse(value),
          },
          {
            label: "View",
            action: (value: any) => this.handleViewCourse(value),
          },
        ];
      }

      ddConfigs = [
        {
          id: "tenant-course-mng-teacher-filter",
          name: "selectedTeacherNameDDValue",
          fieldName: "teacherNameDDMenuItems",
          value: this.getValue(disableForTeacher),
          menuItems: teacherNameDDMenuItems,
          label: "Teacher",
          fieldType: FIELD_TYPES.SELECT,
          placeHolderText: "Select Teacher",
          isDisabled: disableForTeacher,
        },
        {
          id: "tenant-course-mng-subject-filter",
          name: "selectedSubjectHandledDDValue",
          fieldType: FIELD_TYPES.SELECT,
          placeHolderText: "Select Subject",
          fieldName: "subjectHandledDDMenuItems",
          value: "",
          menuItems: subjectsDropdownList,
          label: "Subject",
        },
        {
          id: "tenant-course-mng-gradeLevel-filter",
          name: "selectedGradeLevelDDValue",
          fieldName: "gradeLevelDDMenuItems",
          value: "",
          fieldType: FIELD_TYPES.SELECT,
          placeHolderText: "Select Grade Level",
          menuItems: gradesDropdownList,
          label: "Grade",
        },
        {
          id: "tenant-course-mng-schoolYear-filter",
          name: "selectedSchoolYearDDValue",
          fieldType: FIELD_TYPES.SELECT,
          placeHolderText: "Select School Year",
          fieldName: "schoolYearDDMenuItems",
          value: "",
          menuItems: schoolYearDropdownList,
          label: "School Year",
        },
        {
          fieldType: FIELD_TYPES.SELECT,
          id: "tenant-course-mng-courseUtilization-filter",
          name: "selectedCourseUtilizationDDValue",
          placeHolderText: "Select Option",
          fieldName: "courseUtilizationDDMenuItems",
          value: "",
          menuItems: courseUtilizationDDMenuItems,
          label: "Utilization of the course",
        },
      ];

      this.setState({
        tableBodyData: coursesTableData,
        tableHeaderColumns: coursesTableColumn,
        ddConfig: ddConfigs,
        tableActionButtonConfig: actionButtonConfigs,
      });
    } else {
      ddConfigs = [
        {
          fieldType: FIELD_TYPES.SELECT,
          placeHolderText: "Select Grade Level",
          id: "tenant-course-mng-catalog-grade-filter",
          name: "selectedGradeLevelDDValue",
          fieldName: "gradeLevelDDMenuItems",
          value: "",
          menuItems: gradesDropdownList,
          label: "Grade",
        },
        {
          id: "tenant-course-mng-catalog-curriculum-filter",
          menuItems: curriculumDDMenuItems,
          name: "selectedCurriculumDDValue",
          fieldType: FIELD_TYPES.SELECT,
          placeHolderText: "Select Curriculum",
          fieldName: "curriculumDDMenuItems",
          value: "",
          label: "Curriculum",
        },
        {
          placeHolderText: "Select Option",
          id: "tenant-course-mng-custom-field",
          name: "selectedCustomFieldDDValue",
          fieldType: FIELD_TYPES.SELECT,
          fieldName: "customFieldDDMenuItems",
          value: "",
          menuItems: customFieldDDMenuItems,
          label: "Custom Field",
        },
      ];
      this.setState({
        tableBodyData: catalogList,
        tableHeaderColumns: catalogTableHeaderColumns,
        ddConfig: ddConfigs,
        tableActionButtonConfig: [
          {
            label: "Assign",
            action: (value: any) => this.handleAssignCatalog(value),
          },
          {
            label: "View",
            action: (value: any) => this.handleCatalogNameClick(value),
          },
          {
            label: "Edit",
            action: (value: any) => this.handleEditCatalog(value), 
          },
        ],
      });
      
    }
  };

  handleSelectDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    const { ddConfig } = this.state;
    let fieldValue: any = value;
    if (fieldValue) {
      const updatedDDConfig = ddConfig.map((item) => {
        if (item.name === name) {
          item.value = value;
        }
        return item;
      });
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
          ddConfig: updatedDDConfig,
        };
      });
    }
  };

  handleSearchCourses = () => {
    this.setState({ loading: true });

    const {
      selectedSchoolYearDDValue,
      selectedSubjectHandledDDValue,
      selectedGradeLevelDDValue,
      selectedTeacherNameDDValue,
      selectedCourseUtilizationDDValue
    } = this.state;

    const searchQuery = {
      subject_id: selectedSubjectHandledDDValue,
      grade_id: selectedGradeLevelDDValue,
      school_year_id: selectedSchoolYearDDValue,
      user_id: selectedTeacherNameDDValue,
      utilization_course_id:selectedCourseUtilizationDDValue
    }
    this.handleGetCourseListData(searchQuery, {});
  };

  handleTableAction = (value?: any) => {
    this.setState({ loading: false });
  };

  handleViewCourse = (value?: any) => {
    const { coursesTableData } = this.state;
    const { navigation } = this.props;
    const selectedCourseData = coursesTableData?.find((item: any) => item.id === value);
    const selectedCourseTitle = selectedCourseData?.title?.name || "Physics";
    navigation.navigate("CourseManagementDetails", {}, {
      courseId: selectedCourseData?.id,
      selfCourse: selectedCourseData?.isByTenant ? "MyCourses" : "TeacherCourses",
      courseName: selectedCourseTitle
    })
  }

  openConfirmDialogAndHandleApiCall = (requestBody: any) => {
    this.setState({
      openCreateCourseConfirmPopup: true,
      createUpdateTitle: requestBody?.payload?.courses?.course_title,
      courseRequestBody: requestBody?.payload,
      createUpdateSubject: requestBody?.subjectName,
      createUpdateGrade: requestBody?.gradeName,
    });
  }

  addNewCourseInCurrentCatalog = () => {
    this.setState({shouldCourseAddInCatalog: true});
  }

  handleYesCreateCoursePopup = () => {
    this.setState({ openCreateCourseConfirmPopup: false });
    this.handleAddNewCourse(this.state.courseRequestBody);
    this.addNewCourseInCurrentCatalog();
  }

  handleAddNewCourse = async (requestBody: any) => {
    showCustomLoader();
    this.handleCloseCreateNewCoursePopup();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAddNewCourse = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewCourse
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  resetShouldCourseAddInCatalog = () => {
    this.setState({ shouldCourseAddInCatalog: false });
  };

  addCourseToCatalog = async (catalogId: number, newCourseId: number) => {
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewCatalog}/${catalogId}`
    );
    const courses = this.state.viewCatalogCoursesData;
    const courseIds = courses ? courses.map(datum => datum.id) : [];
    const body = {
      data: {
        attributes: {
          course_ids: [...courseIds, newCourseId]
        }
      }
    }
    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(request.id, request);
  }

  handlePostNewCourseDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { coursesTableData, shouldCourseAddInCatalog, viewCatalogCoursesId} = this.state;
    if (responseJson?.data) {
      const addedCourseItem = this.handleSetCourseDataItem(responseJson.data);
      const updatedCourseList = [
        ...coursesTableData,
        addedCourseItem,
      ];
      let pagination = this.state.paginationData;
      if (pagination.total_count && pagination.total_pages) {
        pagination.total_count += 1;
        pagination.total_pages = Math.ceil(pagination.total_count / pagination.per_page);
      }
      const addRowWiseConfig = this.handleUpdateCatalogTableActionConfigs;
      addRowWiseConfig(updatedCourseList);

      if (shouldCourseAddInCatalog) {
        const currentCatalog = Number(viewCatalogCoursesId);
        const course = Number(responseJson.data.id);

        this.addCourseToCatalog(currentCatalog, course);

        this.resetShouldCourseAddInCatalog();
      }

      this.setState({ coursesTableData: updatedCourseList, paginationData: pagination });
      toast.success(TOASTER_NOTIFICATION_MSG.COURSE_CREATION_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleSetCourseDataItem = (item: any) => {
    const course = item.attributes;
    return {
      id: item?.id,
      title: {
        name: course?.course_title,
        icon: course?.subject ? course?.subject?.icon : '',
        bgColor: course?.subject ? course?.subject?.color : '#000',
        boxWidth: "32px",
        boxHeight: "32px",
      },
      subject: course?.subject ? course?.subject.name : '-',
      grade: course?.grade?.name,
      noOfLessonsPerDay: course?.no_of_lession_a_day,
      totalNumberOfLessons: course?.total_no_of_lessons,
      modifiedBy: `${course?.modified_by?.first_name} ${course?.modified_by?.last_name}`,
      createdBy: `${course?.user?.first_name} ${course?.user?.last_name}`,
      schoolYear: course?.school_year?.title || '-',
      isByTenant: true,
      status: course?.updated_course ? course?.updated_course.status : course?.status,
      subjects:course?.subject
    }
  };

  handleGetCourseListData = async (searchQuery: any, pagination: any , ) => {
    showCustomLoader();
    const { viewCatalogCourses } = this.state;
    let apiConfig = configJSON.getCourseListData;
    if (viewCatalogCourses) {
      apiConfig = configJSON.getCatalogCourses;
    }

    const baseUrl = apiConfig + `?per_page=${pagination.limit || this.state.paginationLimit}`;
    let requestUrl = baseUrl;

    if (this.state.viewCatalogCoursesId) {
      requestUrl = requestUrl + `&catalogue_id=${this.state.viewCatalogCoursesId}`;
    }
    if (searchQuery.subject_id) {
      requestUrl = requestUrl + `&subject_id=${searchQuery.subject_id}`;
    }
    if (searchQuery.grade_id) {
      requestUrl = requestUrl + `&grade_id=${searchQuery.grade_id}`;
    }
    if (searchQuery.user_id) {
      requestUrl = requestUrl + `&user_id=${searchQuery.user_id}`;
    }
    if (searchQuery.school_year_id) {
      requestUrl = requestUrl + `&school_year_id=${searchQuery.school_year_id}`;
    }
    if (searchQuery.utilization_course_id) {
      requestUrl = requestUrl + `&cu=${searchQuery.utilization_course_id}`;
    }
    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCourseList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  sortData = (courseList: CourseDataItem[]) => {
    const compareSubjects = (a: CourseDataItem, b: CourseDataItem) => {
      if (!a || !b || !a.subjects?.id || !b.subjects?.id) return 0;
      const subjectIdA = a.subjects.id;
      
      const subjectIdB = b.subjects.id;
      if (subjectIdA < subjectIdB) return -1;
      if (subjectIdA > subjectIdB) return 1;
      return 0
      ;}

    let sortedData = courseList;
    sortedData.sort(compareSubjects);
    return sortedData;
  }
  handleCourseListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      let courseList = responseJson?.data?.map(
        (item: any, index: number) => {
          return this.handleSetCourseDataItem(item);
        }
      );
        this.setState({
          coursesTableData: courseList || [],
          paginationData: responseJson.meta.pagination,
        }, () => {
          this.handleUpdateCourseTableActionConfigs(courseList);
        });  
      console.log("courseList: ", courseList);
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleUpdateCoursePayload = (requestBody: any) => {
    this.setState({
      createUpdateTitle: requestBody?.payload?.courses?.course_title,
      courseRequestBody: requestBody?.payload,
      createUpdateSubject: requestBody?.subjectName,
      createUpdateGrade: requestBody?.gradeName,
    }, () => {
      this.handleOpenPublishCourseConfirmPopup();
    });
  }

  handleUpdateCourseApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutUpdateCourse = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createNewCourse}/${this.state.editCourseId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateCourseDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { coursesTableData } = this.state;
    if (responseJson?.data?.attributes) {
      const course = responseJson?.data;
      const updatedCourseItem = this.handleSetCourseDataItem(course);
      const updatedCourseList = coursesTableData.map((item: any) => {
        if (parseInt(item.id) === parseInt(updatedCourseItem.id)) {
          item = { ...updatedCourseItem };
        }
        return item;
      });
      this.handleLoadCourseTab();
      this.setState({ coursesTableData: updatedCourseList });
      toast.success(TOASTER_NOTIFICATION_MSG.COURSE_UPDATION_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleGetTeachersDropdownListApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTeachersDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUsersList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleTeachersDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      console.log("teacherList: ", responseJson.data);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.TEACHER_FETCH_ERROR);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetSubjectsDropdownListApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetSubjectsDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsDropdwonData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubjectsDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const subjectsDDList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: _.uniqueId(item.attributes.id),
            value: item.attributes.id,
            label: item.attributes.name,
            grade: item.attributes.grade,
          };
        }
      );
      this.setState({
        subjectsDropdownList: subjectsDDList,
      });
      this.handleSetUserListTableData();
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetGradesDropdownListApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradesDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGradesDropdownData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGradesDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const gradesList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            id: _.uniqueId(item.id),
            value: item.id,
            label: item?.attributes.name,
          };
        }
      );
      this.setState({
        gradesDropdownList: gradesList,
      });
      this.handleSetUserListTableData();
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleSchoolYearDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const schoolYearDDList = responseJson?.data?.map(
        (item: any, index: number) => {
          return {
            value: item.attributes.id,
            id: _.uniqueId(item.attributes.id),
            term: item.attributes.semesters,
            label: item.attributes.title,
          };
        }
      );
      this.setState({
        schoolYearDropdownList: schoolYearDDList || [],
      });
      this.handleSetUserListTableData();
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetSchoolYearDropdownListApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetSchoolYearDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolYearDropdownData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCoursePaginationChange = (data: number) => {
    this.setState({ loading: true });

    const query = this.state.searchTitleText;
    const isSearching = !!query;

    if (isSearching) {
      const pageNumber = data;
      this.searchCourse(query, pageNumber);

      return;
    }

    this.handleGetCourseListData({}, { page: data, limit: this.state.paginationLimit });
  }

  handleAddNewCatalogEvent = (payload: any) => {
    this.setState({ openCreateNewCatalogModal: false }, () => {
      this.handleAddNewCatalogApi(payload);
    })
  }

  handleUpdateCatalogEvent = (payload: any) => {
    this.handleCloseCreateNewCatalogPopup();
    this.handleUpdateCatalogApi(payload);
  }

  handleAddNewCatalogApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAddNewCatalog = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postAddNewCatalog
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostNewCatalogDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { catalogTableData } = this.state;
    if (responseJson?.data) {
      const addedCatalogItem = this.handleSetCatalogItem(responseJson?.data);;
      const updatedCatalogList = [
        ...catalogTableData,
        addedCatalogItem,
      ];

      let pagination = this.state.catalogPaginationData;
      if (pagination.total_count && pagination.total_pages) {
        pagination.total_count += 1;
        pagination.total_pages = Math.ceil(pagination.total_count / pagination.per_page);
      }

      this.setState({
        catalogTableData: updatedCatalogList,
        catalogPaginationData: pagination
      });
      toast.success(TOASTER_NOTIFICATION_MSG.CATALOG_CREATION_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleGetCatalogListApi = async (searchQuery: any, pagination: any) => {
    showCustomLoader();

    const baseUrl = configJSON.postAddNewCatalog + `?per_page=${pagination.limit || this.state.paginationLimit}`;
    let requestUrl = baseUrl;

    if (searchQuery.grade_id) {
      requestUrl = requestUrl + `&grade_id=${searchQuery.grade_id}`;
    }

    if (searchQuery.curriculum_id) {
      requestUrl = requestUrl + `&curriculum_id=${searchQuery.curriculum_id}`;
    }

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCatalogList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCatalogListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const response = responseJson?.data;
      const catalogList = response?.map(
        (item: any, index: number) => {
          return this.handleSetCatalogItem(item);
        }
      );
      this.setState({
        catalogTableData: catalogList || [],
        catalogPaginationData: responseJson?.meta?.pagination,
      }, () => {
        this.handleUpdateCatalogTableActionConfigs(catalogList);
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  /**
   * Sets catalogue search result to table data
   */
  handleSearchCatalogueResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const data = responseJson.data;
      const mapToTableColumn = this.handleSetCatalogItem;
      const tableData = data.map((datum: any) => mapToTableColumn(datum));

      this.setState({ catalogTableData: tableData });
    } else {
      displayApiErrorMsg(responseJson.errors, this.props.navigation);
      this.parseApiCatchErrorResponse(errorResponse);
    }

    hideCustomLoader();
  }

  handleSearchCatalogs = () => {
    const { selectedGradeLevelDDValue, selectedCurriculumDDValue } = this.state;
    console.log("search catalog selectedGradeLevelDDValue: ", selectedGradeLevelDDValue);
    this.handleGetCatalogListApi({ grade_id: selectedGradeLevelDDValue, curriculum_id: selectedCurriculumDDValue }, {});
  }

  handleCatalogPaginationChange = (data: number) => {
    this.setState({ loading: true });
    this.handleGetCatalogListApi({}, { page: data, limit: this.state.paginationLimit });
  }

  setCoursesTabTitle = async () => {
    const { role } = this.state;
    let courseTitle = "My Courses";

    if (role === USER_ROLES_CAMEL.TENANT_ADMIN) {
      courseTitle = "Teachers Courses";
    }

    if (role === USER_ROLES_CAMEL.TEACHER || role === USER_ROLES_CAMEL.PARENT_TEACHER) {
      courseTitle = "My Courses";
    }

    this.setState({
      courseMngTabsList: [
        {
          id: 1,
          label: courseTitle,
          value: "teacher_courses",
        },
        {
          id: 2,
          label: "Catalog",
          value: "catalog",
        },
      ],
    });
  }

  showOnlyForTenantAdmin = () => {
    const { role } = this.state;
    let show = false;
    if (role === USER_ROLES_CAMEL.TENANT_ADMIN) {
      show = true;
    }
    return show;
  }

  handlePublishCourse = (id: any) => {
    const course = this.state.coursesTableData.find((item: any) => item.id === id);
    this.setState({
      createUpdateTitle: course?.title?.name,
      createUpdateGrade: course?.grade,
      createUpdateSubject: course?.subject,
      teacherPublishCourseId: id,
    }, () => {
      this.handleOpenPublishCourseConfirmPopup();
    });
  }

  handleCopyCourse = (id: any) => {
    console.log("copy course: ", id);
    this.setState({ teacherCopyCourseId: id }, () => {
      this.handleTeacherCopyCourseApi({});
    })
  }

  handleOpenPublishCourseConfirmPopup = () => {
    this.setState({ openPublishCourseConfirmPopup: true });
  }

  handleClosePublishCourseConfirmPopup = () => {
    this.setState({ openPublishCourseConfirmPopup: false });
  }

  /**
   * Handles course update
   */
  handleUpdateCourse = (data: any) => { 
    this.handleUpdateCoursePayload(data); 
  }

  handleYesPublishCoursePopup = () => {
    const { isEditCourse, courseRequestBody } = this.state;
    this.handleClosePublishCourseConfirmPopup();
    if (isEditCourse) {
      this.handleCloseCreateNewCoursePopup();
      this.handleUpdateCourseApi(courseRequestBody);
      
    } else {
      this.handleYesConfirmSendApproval();
    }
  }

  handleOpenConfirmSendApprovalDialog = () => {
    this.setState({ openConfirmSendApprovalDialog: true });
  }

  handleCloseConfirmSendApprovalDialog = () => {
    this.setState({ openConfirmSendApprovalDialog: false });
  }

  handleYesConfirmSendApproval = () => {
    const { teacherPublishCourseId } = this.state;
    const requestBody = { data: { course_id: teacherPublishCourseId } };
    this.handleTeacherPublishCourseApi(requestBody);
    this.handleCloseConfirmSendApprovalDialog();
  }

  handleTeacherPublishCourseApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostTeacherPublishCourse = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postPublishCourse
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostTeacherPublishCourseResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      toast.success(TOASTER_NOTIFICATION_MSG.TEACHER_PUBLISH_COURSE_SUCCESS);
      this.handleGetCourseListData({}, {});
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleTeacherCopyCourseApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostTeacherCopyCourse = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createNewCourse}?course_id=${this.state.teacherCopyCourseId}&copy_course=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostTeacherCopyCourseResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      const { coursesTableData } = this.state;
      const addedCourseItem = this.handleSetCourseDataItem(responseJson.data);
      const updatedCourseList = [
        ...coursesTableData,
        addedCourseItem,
      ];
      let pagination = this.state.paginationData;
      if (pagination.total_count && pagination.total_pages) {
        pagination.total_count += 1;
        pagination.total_pages = Math.ceil(pagination.total_count / pagination.per_page);
      }
      this.setState({
        coursesTableData: updatedCourseList,
        paginationData: pagination,
        createUpdateTitle: addedCourseItem.title.name
      }, () => {
        this.handleOpenCourseSuccessDialog();
      });
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleOpenCourseSuccessDialog = () => {
    this.setState({ openCopyCourseSuccessDialog: true });
  }

  handleCloseCourseSuccessDialog = () => {
    this.setState({ openCopyCourseSuccessDialog: false });
    this.handleGetCourseListData({}, {page:this.state.paginationData.current_page});
  }

  handleCatalogNameClick = async (id: string) => {
    const selectedCatalog = await this.state.catalogTableData.find((item: any) => item.id.toString() === id.toString());
    const catalogCourses = selectedCatalog?.courses;
    this.setState({
      viewCatalogCourses: true,
      viewCatalogCoursesId: id,
      viewCatalogCoursesData: catalogCourses,
      viewCatalogCoursesName: selectedCatalog?.title?.name,
    }, () => {
      this.handleSetUserListTableData();
      this.handleGetSubjectsDropdownListApi();
      this.handleGetGradesDropdownListApi();
      this.handleGetSchoolYearDropdownListApi();
      this.handleGetCourseListData({}, {});
    });
  }

  closeCatalogCoursesView = () => {
    this.setState({
      viewCatalogCourses: false,
      viewCatalogCoursesId: '',
      viewCatalogCoursesName: '',
      viewCatalogCoursesData: [],
      coursesTableData: [],
    }, () => {
      this.handleLoadCatalogTab();
    });
  }

  handleEditCatalog = (value: any) => {
    this.setState({ isEditCatalog: true, editCatalogId: value }, () => {
      this.setState({ openCreateNewCatalogModal: true });
    });
  };

  handleUpdateCatalogApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutUpdateCatalog = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAddNewCatalog}/${this.state.editCatalogId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateCatalogDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const { catalogTableData } = this.state;
    if (responseJson?.data?.attributes) {
      const updatedCatalogItem = this.handleSetCatalogItem(responseJson?.data);
      const updatedCatalogList = catalogTableData.map((item: any) => {
        if (parseInt(item.id) === parseInt(updatedCatalogItem.id)) {
          item = { ...updatedCatalogItem };
        }
        return item;
      });
      this.setState({ catalogTableData: updatedCatalogList });
      toast.success(TOASTER_NOTIFICATION_MSG.CATALOG_UPDATION_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleGetCurriculumDropdownApi = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurriculumDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurriculumDropdownData + `?per_page=100`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCurriculumDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const curriculums = responseJson?.data?.map(
        (item: any) => {
          return {
            id: _.uniqueId(item.attributes.id),
            value: item.id,
            label: item.attributes.name,
          };
        }
      );
      this.setState({ curriculumDDMenuItems: curriculums || [] }, () => {
        this.handleSetUserListTableData();
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleSetCatalogItem = (item: any) => {
    const catalog = item.attributes;
    return {
      id: item?.id,
      title: { id: item?.id, name: catalog?.name },
      noOfCourses: catalog?.courses ? catalog?.courses?.length : 0,
      grade: catalog?.grade?.name,
      curriculum: catalog?.curriculum?.name,
      createdBy: `${catalog?.created_by?.first_name || ''} ${catalog?.created_by?.last_name || ''}`,
      courses: catalog?.courses ? catalog?.courses : [],
    }
  }

  handleAssignCatalog = (id: any) => {
    const { catalogTableData } = this.state;
    const catalog = catalogTableData.find((item: any) => item.id == id);
    this.setState({
      assignCatalogDetail: {
        catalogId: id,
        catalogName: catalog?.title?.name,
        curriculumName: catalog?.curriculum,
      }
    }, () => {
      this.handleOpenAssignCatalogModal();
    })
  }

  handleCopyCatalog = (id: any) => {
    const requestBody = {
      catalogue_id: id
    }
    this.handleAddNewCatalogApi(requestBody);
  }

  handleGetTeachersListApi = async (filter: any, pagination: any) => {
    showCustomLoader();

    const baseUrl = configJSON.getUsersList + `?per_page=100`;
    let requestUrl = baseUrl;

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    if (filter.user_type) {
      requestUrl = requestUrl + `&type=${filter.user_type}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTeachersList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeachersListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      let usersData = responseJson?.data?.map(
        (item: any, index: number) => {
          const user = item.attributes;
          return {
            value: item.id,
            label: `${user.first_name} ${user.last_name}`,
            id: _.uniqueId(item.id),
          }
        }
      );

      this.setState({
        teacherNameDDMenuItems: usersData,
      }, () => {
        this.handleSetUserListTableData();
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleOpenAssignCatalogModal = () => {
    this.setState({ openAssignCatalogToTeacherPopup: true });
  }

  handleCloseAssignCatalogModal = () => {
    this.setState({ openAssignCatalogToTeacherPopup: false });
  }

  handleAssignCatalogToTeacher = (items: any) => {
    const { assignCatalogDetail } = this.state;
    const requestBody = {
      catalogue_id: assignCatalogDetail?.catalogId,
      teacher_ids: items,
      removed_teacher_ids: []
    };
    this.handleCloseAssignCatalogModal();
    this.handleAssignCatalogToTeacherApi(requestBody);
    console.log(requestBody, "requestBody")
  }

  /**
   * Sets the state to navigate to assign catalog modal
   */
  navigateToAssignCatalog = () => {
    this.closeAssignedDetailsModal();
    this.handleOpenAssignCatalogModal();
    this.handleAssignCatalog(this.state.viewCatalogCoursesId);
  }


  handleAssignCatalogToTeacherApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAssignCatelogToTeacher = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assignCatalogToTeacher
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAssignCatalogDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      console.log(responseJson, "responseJson")
      toast.success(TOASTER_NOTIFICATION_MSG.ASSIGN_CATELOG_TEACHER_SUCCESS);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleAssignCourseToStudents = async (id: number) => {
    console.log('handleAssignCourseToStudents: ', id);
    const {
      viewCatalogCoursesId,
      viewCatalogCoursesName,
      coursesTableData,
    } = this.state;
    const course = coursesTableData.find((item: any) => item.id === id);
    if (course) {
      this.setState({
        assignCourseDetail: {
          courseId: id,
          courseName: course.title.name,
          catalogId: viewCatalogCoursesId,
          catalogName: viewCatalogCoursesName,
          subjectName: course.subject,
          teacherId: await getStorageData('user_id'),
          teacherName: await getStorageData('user_name'),
          classTitle: '',
        }
      }, () => {
        console.log('assign course: ', this.state.assignCourseDetail);
        this.handleOpenAssignCourseModal();
      });
    }
  }

  handleOpenAssignCourseModal = () => {
    this.setState({ openAssignCourseModal: true });
  }

  handleCloseAssignCourseModal = () => {
    this.setState({ openAssignCourseModal: false });
  }

  handleGetSelectedClassName = (data: string) => {
    let assignData: any = this.state.assignCourseDetail;
    assignData.classTitle = data;
    this.setState({ assignCourseDetail: assignData });
  }

  handleAssignCourseOperation = (payload: any) => {
    console.log('assign payload: ', payload);
    this.handleCloseAssignCourseModal();
    if(this.state.role === USER_ROLES_CAMEL.PARENT_TEACHER){
      this.handleParentAssignCourseToStudentApi(payload);
    }
    else{
      this.handleAssignCourseToStudentApi(payload);
    }
    
    
  }

  /**
   * Sets the state to open AssignedDetailsModal
   */
  openAssignedDetailsModal = () => {
    this.setState({isAssignedDetailsModalOpen: true});
  }

  /**
   * Sets the state to close AssignedDetailsModal
   */
  closeAssignedDetailsModal = () => {
    this.setState({isAssignedDetailsModalOpen: false});
  }

  handleAssignCourseToStudentApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAssignCourseToStudent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assignCourseToStudent
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostAssignCourseToStudentResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      console.log("assign course response: ", responseJson?.data);
      this.handleOpenAssignCourseSuccessDialog();
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleSearchCourseResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    const errorInResponse = (responseJson as { errors: string })?.errors;
    const noData = typeof (responseJson as { data: {} }).data === 'undefined';
    const hasError = errorResponse || errorInResponse || noData;

    if (hasError) {
      displayApiErrorMsg(errorInResponse, this.props.navigation);
      this.parseApiCatchErrorResponse(errorResponse);

      return;
    }

    const courseList = responseJson.data.map(this.handleSetCourseDataItem);
    
    this.setState({
      coursesTableData: courseList || [],
      paginationData: responseJson.meta.pagination,
    });  
    this.handleUpdateCourseTableActionConfigs(courseList);

    hideCustomLoader();
  };



  handleParentAssignCourseToStudentApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiParentPostAssignCourseToStudent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.parentTeacherAssigningCourseToStudentEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleParentPostAssignCourseToStudentResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.message) {
      console.log("assign course response: ", responseJson?.data);
      this.handleOpenAssignCourseSuccessDialog();
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  /**
   * Sets search result to table data
   */
  handleSearchCatalogCourseResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    hideCustomLoader();

    if (responseJson.data) {
      const data = responseJson.data;
      const tableData = data.map((datum: any) => this.handleSetCourseDataItem(datum));

      this.setState((prev) => {
        return {
          ...prev,
          coursesTableData: tableData
        };
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }

    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleOpenAssignCourseSuccessDialog = () => {
    this.setState({ openAssignCourseSuccessDialog: true });
  }

  handleCloseAssignCourseSuccessDialog = () => {
    this.setState({ openAssignCourseSuccessDialog: false });
  }

  rightSideButtons = () => {
    const { selectedCourseManagementTab } = this.state;
    const topBtnStyle = {
      padding: "10px 10px",
      borderRadius: "10px",
      fontSize: "16px",
      fontWeight: 600,
      height: "45px",
      textAlign: "center",
    } as const;

    return (
      <>
        {
          selectedCourseManagementTab === 1 ?
            <>
              {this.showOnlyForTenantAdmin() ?
                this.state.viewCatalogCoursesId ?
                  <>
                    <CustomButton
                      btnStyle={{
                        ...topBtnStyle,
                        width: "176px",
                        marginBottom: "10px",
                      }}
                      btnText="Assigned Details"
                      buttonId="tenant-course-management-assigned-details"
                      buttonVariant={"outlined"}
                      handleButtonClick={this.openAssignedDetailsModal}
                      type={"button"}
                      isDisabled={false}
                    /> 
                    <CustomButton
                      btnStyle={{
                        ...topBtnStyle,
                        width: "176px",
                        marginBottom: "10px",
                        marginLeft: "15px"
                      }}
                      btnText="Add Course"
                      buttonId="tenant-course-management-add-course1"
                      buttonVariant={"contained"}
                      handleButtonClick={() => {this.handleCreateNewCourse()}}
                      type={"button"}
                      isDisabled={false}
                    /> 
                  </>
                  :
                  <CustomButton
                    btnStyle={{
                      ...topBtnStyle,
                      width: "180px",
                      marginBottom: "10px",
                    }}
                    buttonId="tenant-course-management-add-catalog"
                    buttonVariant={"contained"}
                    handleButtonClick={this.handleCreateNewCatalog}
                    type={"button"}
                    btnText="Create New Catalog" 
                    isDisabled={false}
                  />
                :
                <CustomButton
                  btnStyle={{
                    ...topBtnStyle,
                    marginRight: "15px",
                    width: "170px",
                    marginBottom: "10px",
                  }}
                  btnText="My Requests"
                  buttonId="teacher-manage-request"
                  buttonVariant={"contained"}
                  handleButtonClick={this.handleManageRequest}
                  type={"button"}
                  isDisabled={false}
                />
              }
            </>
            :
            <>
              {this.showOnlyForTenantAdmin() &&
                <CustomButton
                  btnStyle={{
                    ...topBtnStyle,
                    marginRight: "15px",
                    width: "170px",
                    marginBottom: "10px",
                  }}
                  btnText="Manage Requests"
                  buttonId="tenant-course-management-manage-request"
                  buttonVariant={"outlined"}
                  handleButtonClick={this.handleManageRequest}
                  type={"button"}
                  isDisabled={false}
                />
              }
              
              <CustomButton
                btnStyle={{
                  ...topBtnStyle,
                  width: "176px",
                  marginBottom: "10px",
                }}
                btnText="Create New Course"
                buttonId="tenant-course-management-add-course"
                buttonVariant={"contained"}
                handleButtonClick={this.handleCreateNewCourse}
                type={"button"}
                isDisabled={false}
              />
            </>
        }
      </>
    );
  }

  handleUpdateCourseTableActionConfigs = async (courseList: any) => {
    const { tableActionButtonConfig } = this.state;
    let rowWiseActions: Array<any> = [];
    const user = await getStorageData('user_name');
    courseList.forEach((course: any) => {
      let menu = tableActionButtonConfig;

      if (course.status !== "Draft" && course.status !== "Active") {
        const updatedMenu = menu.filter((item: any) => item.label != "Send For Approval");
        menu = updatedMenu;
      }
      
      const isOwnedByUser = course.createdBy === user;
      
      if (!isOwnedByUser) {
        const noEdit = menu.filter(item => item.label !== "Edit");
        menu =  noEdit;
      }

      rowWiseActions.push({
        id: course.id,
        menu
      });
    });
    console.log("rowWiseActions: ", rowWiseActions);
    this.setState({ rowWiseTableActionConfig: rowWiseActions });
  }

  handleUpdateCatalogTableActionConfigs = (catalogList: any) => {
    const { tableActionButtonConfig } = this.state;
    let rowWiseActions: Array<any> = [];
    catalogList.forEach((course: any) => {
      rowWiseActions.push({
        id: course.id,
        menu: tableActionButtonConfig
      });
    });
    this.setState({ rowWiseTableActionConfig: rowWiseActions });
  }

  /**
   * Sends a request to remove a course from the catalog
   */
  removeCourseFromCatalog = async (courseId: number) => {
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.removeCourseFromCatalogRequest = request.messageId;

    const endpoint = configJSON.postAddNewCatalog;
    const catalogueId = Number(this.state.viewCatalogCoursesId);
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endpoint}/${catalogueId}`
    );

    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    const currentCourses = this.state.viewCatalogCoursesData.map(datum => Number(datum.id));
    const withRemovedCourse = currentCourses.filter(id => id !== courseId)
    const body = {
      data: {
        attributes: {
          course_ids: withRemovedCourse,
        },
      },
    };
    
    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );


    runEngine.sendMessage(request.id, request);
  }

  /**
   * Handles response for remove course from catalog
   */
  handleRemoveCourseFromCatalogResponse = (responseJson: any, errorResponse: any) => {
    hideCustomLoader();

    if (responseJson.errors) {
      toast.success(TOASTER_NOTIFICATION_MSG.REMOVE_COURSE_CATALOG_FAIL);

      displayApiErrorMsg(responseJson.errors, this.props.navigation);
      this.parseApiCatchErrorResponse(errorResponse);

      return;
    }
 
    toast.success(TOASTER_NOTIFICATION_MSG.REMOVE_COURSE_CATALOG_SUCCESS);
  }
  // Customizable Area End
}
// Customizable Area End
