// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Tabs, Tab } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TenantUserManagementController, {
  Props,
  myPathCategoryTabsList,
} from "./TenantUserManagementController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import UserManagementTabComponent from "./UserManagementTabComponent.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomCSVUploadPopupWeb from "../../../components/src/CustomCSVUploadPopup.web";
import AddUserRoleSelectPopupWeb from "./AddUserRoleSelectPopup.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    mainPagePadding: {
      paddingRight: "20px",
    },
    userMngMainContainer: {
      height: "100%",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 0px 0px",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      backgroundColor: "transparent",
      padding: "10px 4px",
      textAlign: "center",
      textTransform: "capitalize",
      display: "inline-block",
      textDecoration: "none",
      opacity: 1,
      fontSize: "16px",
      minWidth: "33.33%",
      "&.Mui-selected": {
        color:`#FBD82C !important`,
        outline: "none",
        backgroundColor: "transparent",
        borderBottom: `1px solid #FBD82C !important`,
        fontWeight: 600,
      },
      "&.MuiTab-root": {
        minHeight: "60px",
      },
    },
    tabDarkMode: {
      padding: "10px 4px",
      backgroundColor: "transparent",
      textAlign: "center",
      display: "inline-block",
      textTransform: "capitalize",
      textDecoration: "none",
      fontSize: "16px",
      minWidth: "33.33%",
      opacity: 1,
      "&.Mui-selected": {
        color:`#0D46BC !important`,
        outline: "none",
        backgroundColor: "transparent",
        borderBottom: `1px solid #0D46BC !important`,
        fontWeight: 600,
      },
      "&.MuiTab-root": {
        minHeight: "60px",
      },
    },
    tabPanel: {
      padding: "30px 0px 15px 0px",
    },
    userMngTopHeader: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
    topButtons: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
  });

// Customizable Area End

// Customizable Area Start
export class TenantUserManagement extends TenantUserManagementController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      selectedUserManagementTab,
      openImportCSVPopup,
      openUserRoleSelectPopup,
    } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`}
                component={"span"}
              >
                User Management
              </Typography>
              <Typography
                component={"span"}
                className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5}`}
              >
                {` / ${myPathCategoryTabsList[selectedUserManagementTab].label}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Box className={`${classes.userMngTopHeader}`}>
              <Typography
                className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}
                component={"span"}
              >
                User Management
              </Typography>
              <Box className={`${classes.topButtons}`}>
                <CustomButton
                  btnStyle={importBtnStyle}
                  btnText="Import CSV"
                  buttonId="tenant-user-management-import-csv"
                  buttonVariant={"outlined"}
                  handleButtonClick={this.handleOpenImportCsvDialog}
                  type={"button"}
                  isDisabled={false}
                />
                <CustomButton
                  btnStyle={addNewBtnStyle}
                  btnText="Add New User"
                  buttonId="tenant-user-management-add-user"
                  buttonVariant={"contained"}
                  handleButtonClick={this.handleOpenAddUserRoleSelectPopup}
                  type={"button"}
                  isDisabled={false}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid
              className={`${classes.bgColor} ${classes.userMngMainContainer}`}
              container
              direction="row"
            >
              <Grid item xs={12}>
                <Tabs
                  value={selectedUserManagementTab}
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      background: "none",
                    },
                  }}
                  scrollButtons="auto"
                  data-testid="tenant-user-management-tabs"
                  className={`${classes.tabs}`}
                  onChange={this.handleChangeMyPathCategoryTab}
                  aria-label="tenant-user-management-tabs"
                >
                  {myPathCategoryTabsList.map((tabItem: any, index: number) => (
                    <Tab
                      key={tabItem.id}
                      data-testid={`tenant-user-management-${tabItem.label}-tab`}
                      label={tabItem.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${this.handleREnderDarkMode(JSON.parse(localStorage.getItem("dark_mode") || "false"), classes.tabButton, classes.tabDarkMode)} ${classes.textPrimaryColor} ${classes.customTab}`}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid className={`${classes.tabPanel}`} item xs={12}>
                <TabPanel value={selectedUserManagementTab} index={0}>
                  <UserManagementTabComponent navigation={this.props.navigation} tenantUserMngTabCategory="student_parent" />
                </TabPanel>
                <TabPanel value={selectedUserManagementTab} index={1}>
                  <UserManagementTabComponent  navigation={this.props.navigation} tenantUserMngTabCategory="teacher" />
                </TabPanel>
                <TabPanel value={selectedUserManagementTab} index={2}>
                  <UserManagementTabComponent  navigation={this.props.navigation} tenantUserMngTabCategory="other" />
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CustomCSVUploadPopupWeb
          open={openImportCSVPopup}
          handleClose={this.handleCloseImportCsvDialog}
          onFileDrop={this.handleFileUpload}
          onConfirm={this.handleUserImportCSVApi}
          data-testId="import-user-csv"
          downloadCSVFile={this.downloadUserCSVFile}
        />
        <AddUserRoleSelectPopupWeb
          open={openUserRoleSelectPopup}
          handleClose={this.handleCloseAddUserRoleSelectPopup}
          handleNext={this.handleAddUserFlow}
          roleOptions={this.state.roleOptions}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End
const addNewBtnStyle = {
  width: "160px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  height: "45px",
  marginBottom: "10px",
  textAlign: "center",
} as const;

const importBtnStyle = {
  width: "160px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  height: "45px",
  marginBottom: "10px",
  marginRight: "8px",
  textAlign: "center",
} as const;
// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantUserManagement);
// Customizable Area End
