// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

interface ActionBtnConfig {
  label: string;
  action: (value?: any) => void;
}

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  tableHeaderColumns: Array<any>;
  tableBodyData: Array<any>;
  handleAction?: any;
  isHandleCellAction?: boolean;
  tableId: string;
  currentRole?:string;
  showMenuIcon: boolean;
  showPagination?: boolean;
  showPageCount?: any;
  showEditMenuIcon?: boolean,
  actionButtonConfig?: Array<ActionBtnConfig>;
  actionButtonConfigstatus?: Array<ActionBtnConfig>;
  showResourceMenu?: boolean,
  showMyCoursesMenu?: boolean,
  makeRowSelectable?: boolean,
  onRowSelect?: (data: any) => void,
  onColumnSelect?: (data: any) => void,
  showSelectAllCheckbox?: boolean,
  allSelected?: boolean,
  onSelectAllRecords?: (event: any) => void,
  handleDownload?: any;
  showUserActionMenu?: boolean;
  rowWiseTableActionConfig?: any;
  showRowWiseActionMenu?: boolean;
  handleColumnSorting?: any,
  requestIconShow?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  menuAnchorEl: any;
  selectedRowId: any;
  selectedDisable:boolean;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),

    ]

    this.state = {
      loading: false,
      menuAnchorEl: null,
      selectedRowId: null,
      selectedDisable:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  handleActionClick = (e: any, rowItem: any , disable:boolean) => {
    let selectedId: any = "";
    if (rowItem?.index >= 0) {
      selectedId = rowItem.index;
    } else if (rowItem?.id) {
      selectedId = rowItem.id;
    }
    this.setState({ menuAnchorEl: e?.currentTarget, selectedRowId: selectedId 
      ,selectedDisable: disable
    })
  }

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null })
  }
  handleEditActionClick = (e: any, rowItem: any) => {
    let selectedId: any = "";
    if (rowItem?.index >= 0) {
      selectedId = rowItem.index;
    } else if (rowItem?.id) {
      selectedId = rowItem.id;
    }
    this.setState({ menuAnchorEl: e?.currentTarget, selectedRowId: selectedId })
  }

  getUserStatusOption = (label: string) => {
    if (label == 'view') {
      return 'View'
    }
    if(label == "edit"){
      return "Edit"
    }
    if (this.state.selectedRowId) {
      let selectedUser = this.props.tableBodyData.find((item: { id: string }) => item.id === this.state.selectedRowId);
      if (selectedUser && selectedUser.status == 'active') {
        return "Inactive"
      } else if (selectedUser && selectedUser.status == "inactive") {
        return "Active"
      } else if (selectedUser && selectedUser.status == "draft") {
        return "Active"
      }
    }
    return '';
  };


  handleEditMenuClose = () => {
    this.setState({ menuAnchorEl: null })
  }
  // Customizable Area End
}