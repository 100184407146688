// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  IDropdownMenuItemProps,
  ITableActionBtnConfigProps,
  TOASTER_NOTIFICATION_MSG,
} from "../../../components/src/CommonType.web";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../components/src/CommonHelper.web";
import moment from "moment";
import { toast } from "react-toastify";

export const configJSON = require("./config");
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openNewSchoolCalendarDialog: boolean;
  handleCloseSchoolCalendarDialog: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectSchoolYear: string;
  selectSchoolYearItems: Array<IDropdownMenuItemProps>;
  selectCurriculum: string;
  selectCurriculumItems: Array<IDropdownMenuItemProps>;
  schoolCalendarList: Array<any>;
  schoolCalendarTableActionButtonConfig: Array<ITableActionBtnConfigProps>;
  openDeleteSchoolCalendarPopup: boolean;
  openEditNewSchoolCalendarPopup: boolean;
  token: string;
  selectedSchoolCalendarData: any;
  selectedHolidayDetails: any;
  currentPage: number;
  perPageRecords: number;
  totalNumberOfSchoolCalendarRecords: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantAdminSetupSchoolCalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetSchoolCalendarListing: string = "";
  apiPostSchoolCalendarData: string = "";
  apiUpdateSchoolCalendarData: string = "";
  apiDeleteSchoolCalendarData: string = "";
  apiGetCurriculumListing: string = "";
  apiGetSchoolYearListing: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      loading: false,
      selectSchoolYear: "",
      selectSchoolYearItems: [],
      selectCurriculum: "",
      selectCurriculumItems: [],
      schoolCalendarList: [],
      schoolCalendarTableActionButtonConfig: [
        {
          label: "Edit",
          action: (value: any) => this.handleOpenEditSchoolYearPopup(value),
        },
        {
          label: "Delete",
          action: (value: any) =>
            this.handleOpenSchoolCalendarDeleteConfirmModal(value),
        },
      ],
      openDeleteSchoolCalendarPopup: false,
      openEditNewSchoolCalendarPopup: false,
      token: "",
      selectedSchoolCalendarData: "",
      selectedHolidayDetails: "",
      currentPage: 1,
      perPageRecords: 10,
      totalNumberOfSchoolCalendarRecords: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  handleSetUserTokenTenantCalendar = async () => {
    const token = await getStorageData("token");
    this.setState({ token });
  };

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  renderSchoolYearDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { selectSchoolYearItems } = this.state;
    const selectedSchoolYear: any = selectSchoolYearItems.find(
      (item: any) => item?.value === selected
    );
    return selectedSchoolYear?.label;
  };

  renderCurriculumDDValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Curriculum";
    }
    const { selectCurriculumItems } = this.state;
    const selectedCurriculum: any = selectCurriculumItems.find(
      (curriculum: any) => curriculum?.value === selected
    );
    return selectedCurriculum?.label;
  };

  handleSetCurriculumMenuItem = (list: any) => {
    const menuItems = list?.map((item: any) => {
      return {
        id: item?.id,
        value: item?.id,
        label: item?.attributes?.name,
      };
    });
    return menuItems;
  };

  handleSetSchoolYearMenuItem = (list: any) => {
    const menuItems = list?.map((item: any) => {
      return {
        id: item?.id,
        value: item?.id,
        label: item?.attributes?.title,
      };
    });
    return menuItems;
  };

  handleSetCurriculumDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const curriculumList = this.handleSetCurriculumMenuItem(
        responseJson?.data
      );
      this.setState({
        selectCurriculumItems: curriculumList || [],
      });
    }
  };

  handleGetCurriculumDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetCurriculumDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleSetSchoolYearDropdownList = (responseJson: any) => {
    if (responseJson?.data) {
      const schoolYearList = this.handleSetSchoolYearMenuItem(
        responseJson?.data
      );
      this.setState({
        selectSchoolYearItems: schoolYearList || [],
      });
    }
  };

  handleGetSchoolYearDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      this.handleSetSchoolYearDropdownList(responseJson);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetSchoolCalendarList = async (isFilterApplied?: boolean) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const { selectSchoolYear, selectCurriculum, currentPage, perPageRecords } = this.state;
    let page = currentPage;
    if(isFilterApplied) {
      this.setState({ currentPage: 1 });
      page = 1;
    }
    let queryString = "";
    let schoolYearFetchEndpoint = configJSON.schoolCalendarEndpoint;
    if (selectSchoolYear || selectCurriculum) {
      queryString = `&school_year_id=${selectSchoolYear}&curriculum_id=${selectCurriculum}`;
    }
    schoolYearFetchEndpoint = `${schoolYearFetchEndpoint}?page=${page}&per_page=${perPageRecords}${queryString}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolCalendarListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      schoolYearFetchEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetSchoolYearList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSchoolYearListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSchoolYear
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCurriculumList = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurriculumListing = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.curriculumsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSetSchoolCalendarDataItem = (item: any) => {
    let dataItemObj = {
      id: item?.id,
      schoolYearTitle: item?.attributes?.school_year?.title,
      schoolYearId: item?.attributes?.school_year?.id,
      curriculum: item?.attributes?.curriculum?.name,
      curriculumId: (item?.attributes?.curriculum?.id).toString(),
      daysPattern: [ ...item?.attributes?.day_patterns],
      createdOn: moment(item?.attributes?.created_at).format("MMM DD, YYYY"),
      modifiedOn: moment(item?.attributes?.updated_at).format("MMM DD, YYYY"),
      holidays: [...item?.attributes?.holidays],
    };
    return dataItemObj;
  };

  handleSetSchoolCalendarData = (list: any) => {
    const schoolCalendarData = list?.map((item: any) =>
      this.handleSetSchoolCalendarDataItem(item)
    );
    this.setState({ schoolCalendarList: schoolCalendarData });
  };

  handleGetSchoolCalendarDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      this.handleSetSchoolCalendarData(responseJson.data);
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleOpenEditSchoolYearPopup = (value: any) => {
    const { schoolCalendarList } = this.state;
    const selectedSchoolCalendarData = schoolCalendarList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedSchoolCalendarData,
      },
      () => {
        this.setState({ openEditNewSchoolCalendarPopup: true });
      }
    );
  };

  handleCloseEditSchoolCalendarPopup = () => {
    this.setState({ openEditNewSchoolCalendarPopup: false });
    this.props.handleCloseSchoolCalendarDialog();
  };

  handleDeleteSchoolCalendar = async () => {
    this.handleCloseDeleteSchoolCalendarConfirmModal();
    showCustomLoader();
    const { token, selectedSchoolCalendarData } = this.state;
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteSchoolCalendarData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.schoolCalendarEndpoint}/${selectedSchoolCalendarData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteSchoolCalendarResponse = (responseJson: any) => {
    const { schoolCalendarList, selectedSchoolCalendarData } = this.state;
    if (responseJson) {
      const updatedSchoolCalendarList = schoolCalendarList.filter(
        (item) => item.id !== selectedSchoolCalendarData.id
      );
      this.setState({
        schoolCalendarList: updatedSchoolCalendarList,
        selectedSchoolCalendarData: "",
      });
      toast.success(TOASTER_NOTIFICATION_MSG.SCHOOL_CALENDAR_DELETE_SUCCESS);
    } else {
      toast.error(TOASTER_NOTIFICATION_MSG.SCHOOL_CALENDAR_DELETE_FAILURE);
    }
    hideCustomLoader();
  };

  handleOpenSchoolCalendarDeleteConfirmModal = (value?: any) => {
    const { schoolCalendarList } = this.state;
    const selectedSchoolCalendarData = schoolCalendarList.find(
      (item) => parseInt(item.id) === parseInt(value)
    );
    this.setState(
      {
        selectedSchoolCalendarData,
      },
      () => {
        this.setState({ openDeleteSchoolCalendarPopup: true });
      }
    );
  };

  handleCloseDeleteSchoolCalendarConfirmModal = () => {
    this.setState({ openDeleteSchoolCalendarPopup: false });
  };

  handleAddNewSchoolCalendar = (requestBody: any) => {
    this.props.handleCloseSchoolCalendarDialog();
    showCustomLoader();
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostSchoolCalendarData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.schoolCalendarEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateSchoolCalendar = (requestBody: any) => {
    this.handleCloseEditSchoolCalendarPopup();
    showCustomLoader();
    const { token, selectedSchoolCalendarData } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateSchoolCalendarData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.schoolCalendarEndpoint}/${selectedSchoolCalendarData?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePostSchoolCalendarDataResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.SCHOOL_CALENDAR_CREATION_SUCCESS);
      this.handleGetSchoolCalendarList();
    } else if (responseJson?.errors) {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      hideCustomLoader();
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleUpdateSchoolCalendarResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data?.attributes) {
      toast.success(TOASTER_NOTIFICATION_MSG.SCHOOL_CALENDAR_UPDATE_SUCCESS);
      this.handleGetSchoolCalendarList()
    } else {
      //Check Error Response
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }  
    hideCustomLoader()
  };

  handlePageChange = (event: any, page: any) => {
    this.setState({ currentPage: page }, () => {
      this.handleGetSchoolCalendarList();
    });
  };

  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetSchoolCalendarList();
    this.handleGetSchoolYearList();
    this.handleGetCurriculumList();
    this.handleSetUserTokenTenantCalendar();
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiGetSchoolCalendarListing:
            {
              if(responseJson?.meta?.total_records){
                this.setState({ totalNumberOfSchoolCalendarRecords: responseJson.meta.total_records })
              }
              this.handleGetSchoolCalendarDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiGetSchoolYearListing:
            {
              this.handleGetSchoolYearDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiGetCurriculumListing:
            {
              this.handleGetCurriculumDataResponse(responseJson, errorResponse);
            }
            break;
          case this.apiPostSchoolCalendarData:
            {
              this.handlePostSchoolCalendarDataResponse(
                responseJson,
                errorResponse
              );
            }
            break;
          case this.apiDeleteSchoolCalendarData:
            {
              this.handleDeleteSchoolCalendarResponse(responseJson);
            }
            break;
          case this.apiUpdateSchoolCalendarData:
            {
              this.handleUpdateSchoolCalendarResponse(
                responseJson,
                responseJson
              );
            }
            break;
        }
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
