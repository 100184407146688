// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import moment from "moment";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End
const ntc = require("ntcjs");
// Customizable Area Start
const configJSON = require("./config.js");



interface EventData {
  id: string; 
  type: string;
  attributes: EventAttributes;
}

interface EventAttributes {
  title: string; 
  description: string | null; 
  color: string; 
  start_date_and_time: string; 
  end_date_and_time: string; 
  user_id: string | null; 
  created_at: string | null;
  updated_at: string | null; 
  school: string; 
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  handleUpdateAnswer: any;
  events?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedClassComponentDDValue: any;
  gradingComponent: Array<any>;
  openAssignCourseModal: boolean;
  selectStartDate: any;
  selectEndDate: any;
  isCreateModal: boolean;
  searchStudent: string;
  colorChange: any;
  selectEventPopUp: boolean;
  selectEventDetails: {
    time:string;
    id:string;
    title: string;
    date: any;
    desc: any;
    textColor: string;
  };
  openDeletePopUp: boolean;
  openEditPopUp: boolean;
  openCreateSuccessPopUp: boolean;
  description:string;
  eventTime:string;
  validationErrors: {
    searchStudent: string;
    colorChange: string;
    description: string;
    eventTime: string;
    selectEndDate:string;
  };
  isvalidationErrors: {
    searchStudent: false;
    colorChange: false;
    description: false;
    eventTime: false;
    selectEndDate:false;
  };
  events:{
    id: string;
    title: string;
    allDay: boolean;
    start: Date;
    end: Date;
    desc: string;
    color: string;
    textColor: string;
}[];
selctedColorName:string;
endOfMonth:string;
startOfMonth:string
isParent: boolean;
selectedClassName:string;
isParentTeacher:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherCalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  apiCreateEventApiId:string="";
  apiGetEventListId:string="";
  deleteEventApiId:string=""
  apiUpdateEventApiId:string="";
  apiGetClasses:string = '';
  apiGetParentTeacherStudentCallId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      selectEventDetails: {
        time:"",
        id:"",
        title: "",
        date: "",
        desc: "",
        textColor: "",
      },
      selectEventPopUp: false,
      openDeletePopUp: false,
      selectedClassName:"",
      openEditPopUp: false,
      openCreateSuccessPopUp: false,
      selectedClassComponentDDValue: "",
      openAssignCourseModal: false,
      selectStartDate: new Date(),
      selectEndDate: new Date(),
      isCreateModal: false,
      searchStudent: "",
      colorChange: "",
      gradingComponent: [],
      description:"",
      eventTime:this.getCurrentTime(),
      validationErrors:{
        searchStudent: "",
        colorChange:"",
        description: "",
        eventTime: "",
        selectEndDate:""
      },
      isvalidationErrors:{
        searchStudent:false,
        colorChange:false,
        description: false,
        eventTime: false,
        selectEndDate:false
      },
      events:[],
      selctedColorName:"",
      endOfMonth: moment(Date.now()).endOf('month').format('D-MM-YYYY'),
      startOfMonth: moment(Date.now()).startOf('month').format('D-MM-YYYY'),
      isParent: false,
      isParentTeacher:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };


  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setupViewByRole();
    const startOfMonth = moment(Date.now()).startOf('month').format('D-MM-YYYY');  
    const endOfMonth = moment(Date.now()).endOf('month').format('D-MM-YYYY');  
    this.handleGetEventListApi(startOfMonth,endOfMonth)
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps:Props) {
    if (prevProps.events !== this.props.events) {
      this.handleGetEventListApi(this.state.startOfMonth,this.state.endOfMonth)
    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(message);

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiCreateEventApiId:
              this.handleCreateEventResponse(responseJson);
              break;
          case this.apiGetEventListId:
              this.handleGetEventListResponse(responseJson)
              break;
          case this.deleteEventApiId:
              this.handleDeleteEventResponse(responseJson);
              break;
          case this.apiUpdateEventApiId:
              this.handleUpdateEventResponse(responseJson);
              break;
          case this.apiGetClasses:
              this.handleClassesResponse(responseJson);
              break;
          case this.apiGetParentTeacherStudentCallId:
              this.handleGetParentTeacherStudentResponse(responseJson);
              break;

        }
      }
    }
  }

  setupViewByRole = async () => {
    const role = await getStorageData('role');
    
    if (role === "Parent") this.setState({ isParent: true })
    else if(role === "Parent Teacher"){
      this.setState({isParentTeacher:true},()=>{
        this.handleGetParentTeacherStudentApi()
      })
    }
    else{
      this.handleGetClassesApi()
    }
  }
  handleCalendarDropdownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };
  renderCalendarComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "All";
    }
    const { gradingComponent } = this.state;
    const selectedGradingComponents = gradingComponent.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  handleOpenReschedulModal = () => {
    if (this.state.selectedClassComponentDDValue !== '') {
      this.setState({ openAssignCourseModal: true });
    }else if(this.state.isParentTeacher){
      toast.warning('Please select student first')
    }
    else{
      toast.warning('Please select class first')
    }
  };

  handleCloseAssignCourseModal = () => {
    this.setState({ openAssignCourseModal: false });
  };
  selectStartDate = (date: any) => {
    this.setState({
      selectStartDate: date,
    });
  };
  selectEndDate = (date: any) => {
    this.setState({
      selectEndDate: date,
    });
  };
  createEventModal = () => {
    this.setState({
      isCreateModal: true,
    });
  };
  closeCreateModal = () => {
    this.setState({
      isCreateModal: false,
    });
  };
  handleConfirmEvent=()=>{
    this.setState({
      searchStudent:"" ,
      colorChange:"" ,
      description:"" ,
      eventTime:this.getCurrentTime(),
      selectEndDate:new Date(),
      openCreateSuccessPopUp: false,
    })
  }
  handleChange = async (event: any) => {
    const value = event.target.value;

    this.setState((prevState):any => {
      const errors = { ...prevState.validationErrors };
      const isErrors = { ...prevState.isvalidationErrors };
  
    
      if (value.trim() !== "") {
        errors.searchStudent = "";  
        isErrors.searchStudent = false; 
      }
  
      return {
        searchStudent: value,
        validationErrors: errors,
        isvalidationErrors: isErrors,
      };
    });
  };
  colorChangeUpdate = async (color: any) => {
    const n_match = ntc.name(color);
    this.setState((prevState):any => {
      const validationErrors = { ...prevState.validationErrors };
      const isvalidationErrors = { ...prevState.isvalidationErrors };
      if (color) {
        validationErrors.colorChange = ""; 
        isvalidationErrors.colorChange = false; 
      }
  
      return {
        colorChange: color,
        selctedColorName: n_match[1],
        validationErrors,
        isvalidationErrors,
      };
    });
  };
  openPIckCOlor = () => {
    let el = document.getElementById("ColorChange");
    if (el) {
      el.click();
    }
  };
  formatTimeFromISOString(isoString: string): string {
    const date = new Date(isoString);
  
   
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    
   
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; 
    
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${hours}:${minutesFormatted} ${ampm}`;
  }
  
  handleEventSelect = (selectedEvent: any) => {
   
    const startDate = moment(selectedEvent.start);
    const formattedStartDate = startDate.format("MMM DD, YYYY");
    const formattedTime=this.formatTimeFromISOString(selectedEvent.start)
    this.setState({
      selectEventPopUp: true,
      selectEventDetails: {
        id:selectedEvent.id,
        date: formattedStartDate,
        title: selectedEvent.title,
        desc: selectedEvent.desc,
        time:formattedTime,
        textColor: selectedEvent.textColor,
      },
    });
  };
  handleNavigate=(date: any, view: any)=>{
    const startOfMonth = moment(date).startOf('month').format('D-MM-YYYY');
    const endOfMonth = moment(date).endOf('month').format('D-MM-YYYY');
    this.setState({startOfMonth,endOfMonth})
    this.handleGetEventListApi(startOfMonth,endOfMonth);
  }
  closeHandleEventSelect = () => {
    this.setState({
      selectEventPopUp: false,
    });
  };
  handleDeletePopUp = () => {
    this.setState({
      openDeletePopUp: true,
      selectEventPopUp: false,
    });
  };
  handleCloseDeletePopUp = () => {
    this.setState({ openDeletePopUp: false });
  };
  handleEditEvent =()=>{
    this.setState({isCreateModal:true})
  }
  handleEditPopUp = () => {
    this.setState({
      openEditPopUp: true,
      selectEventPopUp: false,
    });
  };
  handleCloseEditPopUp = () => {
    this.setState({
      openEditPopUp: false,
    });
  };
  CreateEventSuccessPopUp = () => {
    this.setState({
      openCreateSuccessPopUp: true,
    });
  };
  CloseEventSuccessPopUp = () => {
    this.setState({
      openCreateSuccessPopUp: false,
    });
  };
  handleUpdateAnswer =(content:string)=>{
    this.setState((prevState): any => {
      const validationErrors = { ...prevState.validationErrors };
      const isvalidationErrors = { ...prevState.isvalidationErrors };
  
      if (content) {
        validationErrors.description = ""; 
        isvalidationErrors.description = false; 
      }
  
      return {
        description: content,
        validationErrors,
        isvalidationErrors,
      };
    });
  }
  handleDeleteEvent =(eventId:string)=>{
    const idEvent= parseInt(eventId)
    this.handleDeleteEventApi(idEvent)
  }
  handleTimeChange = (event: { target: { value: any; }; }) => {
    
    const value = event.target.value;

    this.setState((prevState): any => {
      const validationErrors = { ...prevState.validationErrors };
      const isvalidationErrors = { ...prevState.isvalidationErrors };
  
      if (value) {
        validationErrors.eventTime = ""; 
        isvalidationErrors.eventTime = false; 
      }
  
      return {
        eventTime: value,
        validationErrors,
        isvalidationErrors,
      };
    });
  };
  formatDateTimeWithTime = (date: { getFullYear: () => any; getMonth: () => number; getDate: () => any; }, time: string) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    

    const formattedTime = time || '00:00:00';
    
    return `${year}-${month}-${day} ${formattedTime}`;
  };

  validateEventData = () => {
    const { searchStudent, colorChange, description, selectEndDate, eventTime } = this.state;

  
    const errors:any = {};
    const isErrors:any ={}

    if (!searchStudent || searchStudent.trim() === "") {
      errors.searchStudent = "Event title is required.";
      isErrors.searchStudent=true;
    }
    if (!colorChange) {
      errors.colorChange = "Event color is required.";
      isErrors.colorChange=true;
    }
    if (!description || description.trim() === "") {
      errors.description = "Event description is required.";
      isErrors.description=true;
    }
    if (!selectEndDate || !eventTime) {
      errors.eventTime = "Event time are required.";
      isErrors.eventTime=true
    }
    if (!selectEndDate) {
      errors.selectEndDate = "Event Date are required.";
      isErrors.selectEndDate=true;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ validationErrors: errors, isvalidationErrors: isErrors });
      return false; 
    }
  
    return true; 

  };
  handleCreateEvent = ()=>{
    const isValid = this.validateEventData();

    if (!isValid) {
      return;
    }
    const requestPayload={
      data: {
        title: this.state.searchStudent ,
        color: this.state.colorChange ,
        description: this.state.description ,
        start_date_and_time: this.formatDateTimeWithTime(new Date(this.state.selectEndDate), this.state.eventTime),  
      }
    }
    
    this.handleCreateEventApi(requestPayload)
    
  }
  handleCreateEventApi = async (requestPayload: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };

    let requestUrl =  configJSON.EventEndpoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateEventApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestPayload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCreateEventResponse = (responseJson: {
    data: any,
   errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      this.closeCreateModal()
      this.CreateEventSuccessPopUp()
      this.handleGetEventListApi(this.state.startOfMonth,this.state.endOfMonth)
      toast.success("Event Created!");
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  handleGetEventListApi = async (startDate:string,endDate:string) => {
    showCustomLoader();
    const isParent = this.state.isParent;
    const parentCalendarEndpoint = configJSON.EventEndpoint;

    const requestUrl =  `${isParent ? parentCalendarEndpoint : configJSON.EventEndpoint}?page=1&per_page=100&start_date=${startDate}&end_date=${endDate}`
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetEventListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetEventListResponse = (responseJson: {
    data: EventData[];
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const responseEvents = responseJson.data
      const events = responseEvents.map(event => ({
        id: event.id,
        title: event.attributes.title,
        allDay: true, 
        start: new Date(event.attributes.start_date_and_time),
        end: new Date(event.attributes.start_date_and_time),
        desc:event.attributes.description  || "",
        color: "rgba(255, 158, 0, 0.05)",
        textColor: event.attributes.color 
      }));
      this.setState({events:events})
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader()
  };
  handleDeleteEventApi  = async (eventId: number) => {
    const endpoint = `${configJSON.EventEndpoint}/${eventId}`;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteEventApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteEventResponse = async (responseJson: { success: boolean; errors?: Array<{}> }) => {
    hideCustomLoader();
    if (responseJson.success) {
      this.handleCloseDeletePopUp()
      this.handleGetEventListApi(this.state.startOfMonth,this.state.endOfMonth)
      toast.success("Event deleted successfully!");
    } else {
      displayApiErrorMsg(responseJson.errors || [], this.props.navigation);
    }
  };

  handleUpdateEventApi = async (requestPayload: any,eventId:any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const endpoint = `${configJSON.EventEndpoint}/${eventId}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateEventApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestPayload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeUpdate
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateEventResponse = (responseJson: {
    data: any,
   errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      this.handleGetEventListApi(this.state.startOfMonth,this.state.endOfMonth)
      this.handleCloseEditPopUp() 
       toast.success("Event updated successfully!");
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };
 
  handleGetClassesApi = async () => {
    const requestUrl = configJSON.classesEndpoint;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetClasses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  handleClassesResponse = (res:any)=>{

    if (res.data) {
      let classesDropdwonData = [{
        id: 1,
        label: 'All',
        value: 1,
      }]
      res.data.forEach((item: any) => {
        classesDropdwonData.push({
          label: item.name,
          value: item.id,
          id: item.id,
        });
      }
      );
      this.setState({
        gradingComponent: classesDropdwonData,
      });
    } else {
      displayApiErrorMsg(res.errors, this.props.navigation);
    }
    
  }

  handleGetParentTeacherStudentApi = async () => {
    const userId = await getStorageData("user_id");
    const token = await getStorageData("token");
    const header = { token };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiGetParentTeacherStudentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsEndpoint}/${userId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  handleGetParentTeacherStudentResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const userData = responseJson.data?.attributes;
      const StudentDropdwonAll = [{
        id: "all",
        label: "All",
        value: "all",
      }];
       
      let studentsData = userData?.children?.map(
        (item: any, index: number) => {
          return {
            id: item?.id,
            value: item?.id,
            label: `${item?.first_name} ${item?.last_name}`,
          };
        }
      );
      this.setState({
        gradingComponent: StudentDropdwonAll.concat(studentsData),
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  }
  // Customizable Area End
}
// Customizable Area End
